import React, { useEffect, useState } from 'react';
import axios from 'axios';
import UploadMaterial from '../fileSharingComponents/UploadMaterial';
import DownloadMaterial from '../fileSharingComponents/DownloadMaterial';

const classNames = {
    2311: "Discrete Structures", 
    2321: "Data Structures",
    3331: "Concurrent Computing", 
    3411: "Systems Programming"
};

function FileSharing({ courseID }) {
    const [uploadMessage, setUploadMessage] = useState(null);
    const [materialList, setMaterialList] = useState(null);

    useEffect(() => {
        fetchFiles();
    }, [courseID]);

    const fetchFiles = () => {
        axios.get(`https://api.sam.cs.mtu.edu/api/sam/materials/view/${courseID}`, {
            withCredentials: true
        }).then((response) => {
            let matMap = new Map();
            response.data.returnArr.forEach(entry => {
                matMap.set(entry.materialID, {
                    id: entry.materialID,
                    fileLocation: entry.fileLocation,
                    name: entry.materialName,
                    postTime: entry.uploadTime,
                    comments: entry.comments
                });
            });
            setMaterialList(matMap);
        }).catch(error => console.log(error));
    };

    const uploadPost = (form) => {
        axios.post(`https://api.sam.cs.mtu.edu/api/sam/materials/upload/${courseID}`, form, {
            withCredentials: true,
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then(() => {
            fetchFiles();
            setUploadMessage("Upload Successful");
        }).catch(error => {
            setUploadMessage("Upload Failed!");
            console.log(error);
        });
    };

    /*
        To delete a post use the /api/sam/deletematerials/:id endpoint with a delete request and with the id equal to the material id
        that is sent along with the other material information to the frontend on the inital fetch
    */

    return (
        <div className="container my-5">
            <div className="row justify-content-center">
                <div className="col-md-8">
                    <h4 className="text-center text-dark mb-5">Course Materials CS{courseID}</h4>
                    <DownloadMaterial
                        courseID={courseID}
                        entries={materialList}
                        fetchFiles={fetchFiles}
                    />
                    <UploadMaterial 
                        courseID={courseID} 
                        upload={uploadPost} 
                        message={uploadMessage} 
                        setMessage={setUploadMessage} 
                    />
                </div>
            </div>
        </div>
    );
}

export default FileSharing;
