import React from 'react';

const CarouselImage = ({ image, text }) => {
  return (
    <div style={{ position: 'relative' }}>
      <img
        className="d-block w-100"
        src={image}
        alt={text}
        style={{ height: '60vh', objectFit: 'cover' }}
      />
       <div
        className="image-overlay"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
        }}
      />
    </div>
  );
};

export default CarouselImage;
