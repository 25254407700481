import React, { useState, useEffect, useRef } from 'react';

function UploadMaterial({ upload, message, setMessage }) {
  const [showFields, setShowFields] = useState(false);
  const [previewContent, setPreviewContent] = useState(null);
  const [previewType, setPreviewType] = useState(null); // Track the type of file for conditional rendering
  const clearMessage = useRef(null);

  useEffect(() => {
    if (message !== null) {
      clearTimeout(clearMessage.current);
      clearMessage.current = setTimeout(() => {
        setMessage(null);
      }, 10000);
    }
  }, [message]);

  const clearFields = () => {
    document.getElementById("samMaterialUpload1").value = null;
    setPreviewContent(null);
    setPreviewType(null);
    setShowFields(false);
  };

  const uploadFile = () => {
    let form = new FormData();
    const matFile = document.getElementById('samMaterialUpload1');
    form.append('file', matFile.files[0]);
    form.append('comments', document.getElementById("uploadMaterialsComments").value);
    upload(form);
    clearFields();
  };

  const checkField = (event) => {
    setMessage(null);
    setShowFields(true);

    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;

      // Preview for image files
      if (fileType.startsWith("image/")) {
        setPreviewType("image");
        setPreviewContent(URL.createObjectURL(file));
      }
      // Preview for PDF files
      else if (fileType === "application/pdf") {
        setPreviewType("pdf");
        setPreviewContent(URL.createObjectURL(file));
      }
      // Preview for text files
      else if (fileType.startsWith("text")) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setPreviewType("text");
          setPreviewContent(e.target.result);
        };
        reader.readAsText(file);
      }
      // Preview unavailable for other file types (e.g., .docx)
      else {
        setPreviewType("unsupported");
        setPreviewContent(file.name); // Show the file name instead
      }
    }
  };

  const getFields = () => {
    return (
      <div className="mt-2">
        <div className="mb-3">
          <label htmlFor="uploadMaterialsComments" className="form-label">Comments:</label>
          <input
            id="uploadMaterialsComments"
            type="text"
            className="form-control"
            placeholder="Optional"
          />
        </div>
        <div className="d-flex gap-2">
          <button className="myBtn" onClick={uploadFile} id="uploadMaterialsSubmit">Upload</button>
          <button className="mySecBtn" onClick={clearFields} id="uploadMaterialsCancel">Cancel</button>
        </div>
      </div>
    );
  };

  return (
    <div className="container my-4">
      <h5 className="text-left text-dark mb-3">Upload Course Materials</h5>
      <div className="mb-3">
        <input
          type="file"
          className="form-control"
          id="samMaterialUpload1"
          onChange={checkField}
          required
        />
      </div>
      {showFields && getFields()}
      
      {/* Display file preview based on type */}
      {previewContent && (
        <div className="mt-4">
          <h5 className="text-dark">File Preview:</h5>
          {previewType === "image" && <img src={previewContent} alt="Preview" className="preview-image" />}
          {previewType === "pdf" && <iframe src={previewContent} width="100%" height="500px" title="PDF Preview" />}
          {previewType === "text" && <pre className="preview-box">{previewContent}</pre>}
          {previewType === "unsupported" && <p className='text-danger'>Preview not available for this file type. File name: {previewContent}</p>}
        </div>
      )}
      
      {message && <p className="alert alert-info mt-3">{message}</p>}
    </div>
  );
}

export default UploadMaterial;
