import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import SearchBar from "../SearchBar";
import MultiSelect from "../MultiSelect";
import StudentEntry from "./StudentEntry";

function UpdateAttendance({ sessions, selectedCourse, studentList }) {
  const [selectedSessionID, setSelectedSessionID] = useState(0);
  const [attendanceRecords, setAttendanceRecords] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const [attendanceMessage, setAttendanceMessage] = useState(null);
  const selectedStudents = useRef([]);
  const selectedSesh = useRef();
  const messageTimeout = useRef(null);

  useEffect(() => {
    if (attendanceMessage !== null && attendanceMessage !== "Processing...") {
      clearTimeout(messageTimeout.current);
      messageTimeout.current = setTimeout(() => {
        setAttendanceMessage(null);
      }, 10000);
    }
  }, [attendanceMessage]);

  useEffect(() => {
    setSelectedSessionID(0);
    setShowSearch(false);
    setAttendanceMessage(null);
  }, [selectedCourse]);

  useEffect(() => {
    if (selectedSessionID !== 0) {
      fetchAttendance();
    } else {
      setAttendanceRecords(null);
      setShowSearch(false);
      setAttendanceMessage(null);
    }
  }, [selectedSessionID]);

  const fetchAttendance = () => {
    axios
      .get(`https://api.sam.cs.mtu.edu/api/sam/getAttendance/${selectedSessionID}`, { withCredentials: true })
      .then((response) => {
        setAttendanceRecords(response.data.returnArr);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (attendanceRecords !== null) updateSelections();
  }, [attendanceRecords]);

  const updateSelections = () => {
    selectedStudents.current = attendanceRecords ? attendanceRecords.map((entry) => entry[0]) : [];
    generateSearch();
  };

  const generateSearch = () => {
    setShowSearch(
      <div>
        <h5 className="text-dark mb-3">Update Attendance</h5>
        <MultiSelect entries={studentList} selections={selectedStudents} />
        <div className="d-flex gap-2 mt-3">
          {/* <button className="myBtn" onClick={updateAttendance}>Submit</button> */}
          <button className="myBtn" onClick={resetSelections}>Submit</button>
          <button className="mySecBtn" onClick={resetSelections}>Cancel</button>
        </div>
      </div>
    );
  };

  const updateAttendance = () => {
    const attendanceArr = studentList.map((student) => [
      student[1],
      selectedStudents.current.includes(student[1]),
    ]);

    setAttendanceMessage("Processing...");
    axios
      .post(
        `https://api.sam.cs.mtu.edu/api/sam/updateAttendance/${selectedSessionID}`,
        {
          withCredentials: true,
          studentList: attendanceArr,
        },
      )
      .then(() => {
        fetchAttendance();
        setAttendanceMessage("Attendance Updated!");
      })
      .catch((error) => console.log(error));
  };

  const resetSelections = () => {
    setAttendanceMessage("");
    setSelectedSessionID(0);
  };

  const sessionValues = () => {
    const sessionEntries = sessions
      .filter((session) => session.courseID == selectedCourse)
      .map((session) => [session.Date, session.sessionID]);

    return (
      <SearchBar
        name={"sessions"}
        entries={sessionEntries}
        placeholderText={"Please select a session"}
        selections={selectedSesh}
        setState={setSelectedSessionID}
        selectState={selectedSessionID}
        isMulti={false}
      />
    );
  };

  const updateStudentBonusPoints = (studentUsername, pointCount) => {
    axios
      .patch(`https://api.sam.cs.mtu.edu/api/sam/bonuspoints/${selectedSessionID}`, {
        studentUsername,
        bonusPoints: pointCount,
      })
      .then(() => {
        fetchAttendance();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStudents = () => {
    return attendanceRecords.map((student) => (
      <StudentEntry
        key={`studentAttendanceEntry-${student[0]}-${selectedSessionID}`}
        studentName={student[1]}
        studentUsername={student[0]}
        bonusPointCount={student[2]}
        updatePoints={updateStudentBonusPoints}
      />
    ));
  };

  const getAttendanceRecords = () => (
    <>
      <h5 className="text-dark mt-4">Students Attended: {attendanceRecords.length}</h5>
      <div className="list-group mt-3">{getStudents()}</div>
    </>
  );

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="container mt-4 col-12 col-lg-6 col-md-8">
        <h4 className="text-center text-dark mb-5">Attendance</h4>
        {sessions && sessionValues()}
        {attendanceRecords && getAttendanceRecords()}
        {showSearch && <div className="mt-4">{showSearch}</div>}
        {attendanceMessage && (
          <p className="alert alert-info mt-3">{attendanceMessage}</p>
        )}
      </div>
    </div>
  );
  
}

export default UpdateAttendance;
