import React from "react";
import { useState, useEffect, useRef } from "react";
function UploadRoster({ uploadFunction, defaultMessage }) {
  const [message, setMessage] = useState(defaultMessage);
  const [displayButton, setDisplayButton] = useState(false);
  const oldTimeout = useRef(null);
  useEffect(() => {
    if (message !== null && message !== undefined) {
      clearTimeout(oldTimeout.current);
      oldTimeout.current = setTimeout(() => {
        setMessage(null);
      }, 30000);
    }
  }, [message]);

  const checkUpload = () => {
    if (event.target.files[0].type !== "text/csv") {
      setMessage("Please only upload a .csv file");
      event.target.value = null;
    } else {
      setMessage(null);
      setDisplayButton(true);
    }
  };
  const uploadFile = () => {
    const field = document.getElementById("uploadRosterField");
    if (field.files.length === 0) {
      setMessage("Please upload a .csv file");
    } else if (field.files[0].type !== "text/csv") {
      setMessage("Please only upload a .csv file");
      event.target.value = null;
    } else {
      let form = new FormData();
      form.append("file", field.files[0]);
      setMessage("Processing... Please wait");
      uploadFunction(form);
      setDisplayButton(false);
    }
    // console.log(field.files);
  };
  return (
    <div className="uploadRosterWrapper">
      <h2 className="advisorDashSubheader">Upload Class Roster</h2>
      <div className="uploadTopSegment">
        <p className="uploadRosterMessage">
          Please upload the .csv student list from Canvas
        </p>
        <input
          accept=".csv"
          id="uploadRosterField"
          type="file"
          onChange={checkUpload}
        />
      </div>
      <div className="uploadBottomSegment">
        {displayButton ? (
          <button className="submitRosterButton" onClick={uploadFile}>
            Submit
          </button>
        ) : null}
        <p className="uploadRosterDebugMessage">{message}</p>
      </div>
    </div>
  );
}

export default UploadRoster;
