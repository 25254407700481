import React, { useState, useEffect } from 'react';
import photo from "../assets/homepagePhotos/photo-10.jpg";
import Typewriter from "typewriter-effect";

const CourseBanner = ({ samSays }) => {
    const [quoteIndex, setQuoteIndex] = useState(0);

    // Filter only active quotes
    const activeQuotes = samSays.filter(quote => quote.active === 1);

    useEffect(() => {
        // Reset to the first quote if `activeQuotes` changes or `quoteIndex` exceeds the length of the array
        if (quoteIndex >= activeQuotes.length) {
            setQuoteIndex(0);
        }
    }, [activeQuotes, quoteIndex]);

    // Auto-increment quoteIndex every 4 seconds to move to the next quote
    useEffect(() => {
        const interval = setTimeout(() => {
            setQuoteIndex((prevIndex) => (prevIndex + 1) % activeQuotes.length);
        }, 4000); // Adjust the interval as needed
        return () => clearTimeout(interval);
    }, [quoteIndex, activeQuotes.length]);

    return (
        <div style={{ position: 'relative' }}>
            {/* Background Image */}
            <img
                className="d-block w-100"
                src={photo}
                alt="image"
                style={{ height: '30vh', objectFit: 'cover' }}
            />
            {/* Dark Overlay */}
            <div
                className="image-overlay"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                }}
            />
            {/* Centered Text */}
            {activeQuotes.length > 0 && (
                <div
                    className="centered-text samText"
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: 'white',
                        textAlign: 'center',
                    }}
                >
                    <h2>
                        <Typewriter
                            key={quoteIndex} // Key changes whenever the quoteIndex updates
                            onInit={(typewriter) => {
                                typewriter
                                    .typeString(`"${activeQuotes[quoteIndex]?.message}"`)
                                    .pauseFor(2000)
                                    .start();
                            }}
                        />
                    </h2>
                    <p>- {activeQuotes[quoteIndex]?.SAM}</p>
                </div>
            )}
        </div>
    );
};

export default CourseBanner;
