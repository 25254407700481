import React, { useState, useEffect } from 'react';

class Session {
    constructor(time24, time12, location, endtime12) {
        this.time24 = time24; // Store 24-hour format time for sorting
        this.time = time12;    // Store formatted 12-hour format time for display
        this.location = location;
        this.sam = [];
        this.endtime = endtime12;
    }
    addSam(sam) {
        if (!this.sam.includes(sam)) {
            this.sam.push(sam);
        }
    }
}

const CourseSchedule = ({ courseID, scheduleData }) => {
    const [sessionsByWeekday, setSessionsByWeekday] = useState({});

    // Helper function to format time from 24-hour to 12-hour format
    function getFormattedTime(time) {
        let [hours, minutes] = time.split(":");
        const ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12 || 12;
        return `${hours}:${minutes} ${ampm}`;
    }

    // Helper function to convert time to a comparable 24-hour number format for sorting
    function timeToNumber(time) {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes; // Return total minutes from midnight for easy comparison
    }

    useEffect(() => {
        // Reset the session state on each change of scheduleData
        const updatedSessions = {};

        // Adds or merges sessions for a specific weekday
        scheduleData.forEach((session) => {
            const { weekday, sessionTime, sessionLocation, fullName, endTime } = session;
            const formattedTime = getFormattedTime(sessionTime);
            const formattedEndTime = getFormattedTime(endTime);

            if (!updatedSessions[weekday]) {
                updatedSessions[weekday] = [];
            }

            // Check if a session with the same time and location exists for merging
            const existingSession = updatedSessions[weekday].find(
                (s) => s.time === formattedTime && s.location === sessionLocation
            );

            if (existingSession) {
                existingSession.addSam(fullName);
            } else {
                // Create a new session and add it to the weekday array
                const newSession = new Session(sessionTime, formattedTime, sessionLocation, formattedEndTime);
                newSession.addSam(fullName);
                updatedSessions[weekday].push(newSession);
            }
        });

        // Sort sessions within each weekday by 24-hour format time for accurate ordering
        Object.keys(updatedSessions).forEach((weekday) => {
            updatedSessions[weekday].sort((a, b) => timeToNumber(a.time24) - timeToNumber(b.time24));
        });

        setSessionsByWeekday(updatedSessions);
    }, [scheduleData]);

    return (
        <div className="row justify-content-center">
            {Object.entries(sessionsByWeekday).map(([weekday, sessions], index) => (
                <div className="col-lg-3 col-md-6" key={index}>
                    <div className="services-7">
                        <h4 className="title">{weekday}</h4>
                        {sessions.map((session, i) => (
                            <div key={i} style={{ marginBottom: '20px' }}>
                                <p><strong>Time:</strong> {session.time} - {session.endtime}</p>
                                <p><strong>Location:</strong> {session.location}</p>
                                <p><strong>Instructors:</strong> {session.sam.join(', ')}</p>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CourseSchedule;
