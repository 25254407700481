import React, { useState, useContext } from "react";
import "../Styles/Login.css";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import UserProfile from "../userData/UserProfile";
import { Context } from "./App";
import { Timeout } from "./App";
import axios from "axios";
function Login() {
  const [userInfo, setUserInfo] = useContext(Context);
  const [sessionEnd, setSessionEnd] = useContext(Timeout);
  const [errorState, setErrorState] = useState("");
  const navigate = useNavigate();
  //Login handler that verifies their account with google, checks their session with the API, and then gets their roles from the Database
  const success = (codeResponse) => {
    axios
      .post(
        "https://api.sam.cs.mtu.edu/api/auth/google",
        {
          credential: codeResponse["credential"],
        },
        { withCredentials: true },
      )
      .then((response) => {
        setSessionEnd(new Date(response.data.expiresAt).getTime());
        const name = response.data.name;
        UserProfile.registerUser(name);
      })
      .then(() => {
        axios
          .get("https://api.sam.cs.mtu.edu/api/auth/check-session", {
            withCredentials: true,
          })
          .then((response) => {
            axios
              .get("https://api.sam.cs.mtu.edu/api/auth/getroles", {
                withCredentials: true,
              })
              .then((response) => {
                const roleBits = response.data[0][0].Roles;
                const digits = roleBits.toString().split("");
                let adminStatus = parseInt(digits[0]) === 1;
                let samStatus = parseInt(digits[1]) === 1;
                let advisorStatus = parseInt(digits[2]) === 1;
                let studentStatus = parseInt(digits[3]) === 1;
                UserProfile.setRoles({
                  Student: studentStatus,
                  SAM: samStatus,
                  Advisor: advisorStatus,
                  Admin: adminStatus,
                });
                setUserInfo({
                  Roles: {
                    Student: studentStatus,
                    SAM: samStatus,
                    Advisor: advisorStatus,
                    Admin: adminStatus,
                  },
                  Signin: true,
                });
                checkRedirect();
              })
              .catch((error) => console.error("Error:", error));
          })
          .catch((error) => {
            setErrorState(
              "Login Failed! Please make sure to sign in with a @mtu.edu gmail account",
            );
          });
      })
      .catch((error) => {
        setErrorState("Login Failed!");
      });
  };

  //Helper function to determine where to redirect a user based on their roles
  const checkRedirect = () => {
    let roles = UserProfile.getRoles();
    if (roles !== undefined) {
      if (roles.Student) {
        navigate("/account");
        return;
      }
      if (roles.SAM) {
        navigate("/sam");
        return;
      }
      if (roles.Advisor) {
        navigate("/advisor");
        return;
      }
      navigate("/advisor");
      if (!roles.Student && !roles.SAM && !roles.Advisor) {
        navigate("/");
        return;
      }
    }
  };
  return (
    <div className="loginWrapper">
      {userInfo.Signin ? checkRedirect() : null}
      <h1 className="loginHead">
        Please sign in with your mtu.edu google account
      </h1>
      {!userInfo.Signin ? (
        <GoogleLogin
          id="loginPage"
          onSuccess={(codeResponse) => success(codeResponse)}
          onError={(error) => console.log("Login Failed:", error)}
        />
      ) : null}

      {errorState.length !== 0 ? (
        <p className="loginError">{errorState}</p>
      ) : null}
    </div>
  );
}
export default Login;
