import React from "react";
import ReactQuill from "react-quill";
/*
    This component just displays the passed in problem information
*/
function ProblemInfo({data}){

    const modules = {
        toolbar: false,
    };

    return(
        <div className="mt-3">
            <h5 className="text-black mt-5 mb-2">{data?.name}</h5>
            <ReactQuill
                value={data?.description}
                readOnly={true}
                modules={modules}
                theme="snow"
                className="bootstrap-quill"
            />
            {
                data.hint !== null ?
                    <>
                        <h5 className="text-black mt-5 mb-2">Hint</h5>
                        <ReactQuill
                            value={data?.hint}
                            readOnly={true}
                            modules={modules}
                            theme="snow"
                            className="bootstrap-quill"
                        />
                    </>
                    : null
            }
            {
                data.comments !== null ?
                    <>
                        <h5 className="text-black mt-5 mb-2">Comments</h5>
                        <ReactQuill
                            value={data?.comments}
                            readOnly={true}
                            modules={modules}
                            theme="snow"
                            className="bootstrap-quill"
                        />
                    </>
                    : null
            }
        </div>
    )
}

export default ProblemInfo;