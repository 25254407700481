import React from "react";
import "../../Styles/Advisor.css";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
function SessionAttendanceData({ sessionID, selectedCourse }) {
  const [attendanceData, setAttendanceData] = useState(null);
  const [samList, setSamList] = useState(null);
  const [fullBlock, setFullBlock] = useState(null);
  //Clearing all fields whenever course selection is changed
  useEffect(() => {
    clearFields();
  }, [selectedCourse]);
  //Fetching attendance when session is updated or clearing field if advisor sets selection back to default
  useEffect(() => {
    if (sessionID !== 0) {
      fetchAttendance();
    } else {
      setSamList(null);
      setAttendanceData(null);
    }
  }, [sessionID]);
  //Calling backend to fetch attendance and update applicable states
  const fetchAttendance = () => {
    axios
      .get(
        `https://api.sam.cs.mtu.edu/api/advisor/getSessionAttendance/${sessionID}`,
        {
          withCredentials: true,
        },
      )
      .then((response) => {
        setAttendanceData(response.data.attendanceList);
        setSamList(response.data.samList);
      })
      .catch((error) => console.log(error));
  };
  //Formats attendance list with messages displaying total student count or no attendance message
  const getAttendanceInfo = () => {
    if (attendanceData.length === 0) {
      setFullBlock(
        <h3 className="advisorSectionSubsubheader">
          No students attended this session
        </h3>,
      );
    } else {
      setFullBlock(
        <div className="advisorDashAttendanceInfoWrapper">
          <h3 className="advisorSectionSubsubheader">
            Session was Lead By {getSams()}
          </h3>
          <ul className="advisorDashStudentList">{getStudents()}</ul>
          <p className="advisorDashboardParagraph">
            There was a total of {attendanceData.length} students who attended
          </p>
        </div>,
      );
    }
  };
  //Gets list of sams formatted properly to print out who led the session
  const getSams = () => {
    if (samList.length < 2) return samList[0];
    let returnStatement = "";
    for (let i = 0; i < samList.length; i++) {
      if (i === samList.length - 1) {
        returnStatement += samList[i];
      } else {
        returnStatement += samList[i] + " and ";
      }
    }
    return returnStatement;
  };
  //Creating li element for each student
  const getStudents = () => {
    let studentList = [];
    attendanceData.forEach((student) => {
      studentList.push(
        <li
          key={`sessionAttendance-${sessionID}-${student}`}
          className="advisorDashStudentListItem"
        >
          {student}
        </li>,
      );
    });
    return studentList;
  };
  //Reseting all states back to null
  const clearFields = () => {
    setAttendanceData(null);
    setSamList(null);
    setFullBlock(null);
  };
  //Recreating the attendance info headers/list whenever the attendance data and sam list is updated
  useEffect(() => {
    if (attendanceData !== null && samList !== null) getAttendanceInfo();
  }, [attendanceData, samList]);
  return <div className="sessionAttendanceDataWrapper">{fullBlock}</div>;
}

export default SessionAttendanceData;
