import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ConfirmationModal from "../../SAMDashComponents/ConfirmationModal";

function ViewProblem({ problemInfo, updateFunction, deleteFunction, categories }) {
  const [selectedCategory, setSelectedCategory] = useState(problemInfo[0]);
  const [isEditing, setIsEditing] = useState(false);
  const [problemMessage, setProblemMessage] = useState(null);
  const [desc, setDesc] = useState(problemInfo[2] || "");
  const [name, setName] = useState(problemInfo[1] || "");
  const [hint, setHint] = useState(problemInfo[3] || "");
  const [comment, setComment] = useState(problemInfo[4] || "");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [newCategory, setNewCategory] = useState("");

  const updateProblem = () => {
    if (!desc) {
      setProblemMessage("Problem Description cannot be empty");
      return;
    }
    if (!name) {
      setProblemMessage("Problem Name cannot be empty");
      return;
    }

    const category = selectedCategory == -1 ? newCategory : selectedCategory;
    if (selectedCategory == -1 && !newCategory) {
      setProblemMessage("Category cannot be empty");
      return;
    }
    const problemData = [desc, name, hint || null, comment || null, category];
    updateFunction(problemData);
    setIsEditing(false);
  };

  // Quill editor modules and formats
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline", "strike"],
      [{ align: [] }],
      ["link"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "align",
  ];

  useEffect(() => {
    if (problemMessage != null) {
      const timer = setTimeout(() => {
        setProblemMessage(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [problemMessage]);

  return (
    <div className="container p-4 border rounded">
      <h5 className="mb-4 text-black">View Problem</h5>

      {problemMessage && (
        <p className="alert alert-warning mt-3">{problemMessage}</p>
      )}

      <div className="mb-3">
        <label className="form-label">Category</label>
        <select
          className="form-select"
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          disabled={!isEditing}
        >
          <option value={0}>Please select a category</option>
          <option value={-1}>Create new category</option>
          {categories?.map((entry) => (
            <option key={entry} value={entry}>
              {entry}
            </option>
          ))}
        </select>
      </div>
      
      {selectedCategory == -1 && (
        <div className="mb-3">
          <label className="form-label">New Category</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter new category"
            value={newCategory}
            disabled={!isEditing}
            onChange={(e) => setNewCategory(e.target.value)}
          />
        </div>
      )}

      <div className="mb-3">
        <label className="form-label">Problem Name</label>
        <input
          type="text"
          className="form-control"
          value={name}
          onChange={(e) => setName(e.target.value)}
          disabled={!isEditing}
        />
      </div>

      <div className="mb-3">
        <label className="form-label">Description</label>
        <ReactQuill
          theme={"snow"}
          value={desc}
          readOnly={!isEditing}
          onChange={setDesc}
          modules={modules}
          formats={formats}
          className="bootstrap-quill"
        />
      </div>

      <div className="mb-3">
        <label className="form-label">Hint</label>
        <ReactQuill
          theme={"snow"}
          value={hint}
          readOnly={!isEditing}
          onChange={setHint}
          modules={modules}
          formats={formats}
          className="bootstrap-quill"
        />
      </div>

      <div className="mb-3">
        <label className="form-label">Comments</label>
        <ReactQuill
          theme={"snow"}
          value={comment}
          readOnly={!isEditing}
          onChange={setComment}
          modules={modules}
          formats={formats}
          className="bootstrap-quill"
        />
      </div>

      <div className="d-flex gap-2">
        <button
          className="myBtn"
          onClick={() => (isEditing ? updateProblem() : setIsEditing(true))}
        >
          {isEditing ? "Update" : "Edit"}
        </button>
        <button className="myDanBtn" onClick={() => setShowDeleteModal(true)}>
          Delete
        </button>
        {isEditing && (
          <button className="mySecBtn" onClick={() => setIsEditing(false)}>
            Cancel
          </button>
        )}
      </div>

      <ConfirmationModal
        show={showDeleteModal}
        title="Confirm Deletion"
        message="Are you sure you want to delete this problem?"
        onConfirm={() => {
          deleteFunction();
          setShowDeleteModal(false);
        }}
        onCancel={() => setShowDeleteModal(false)}
      />
    </div>
  );
}

export default ViewProblem;
