import React from "react";
import { useState, useEffect, useRef } from "react";

function FullAttendance({
  attendanceData,
  scheduleID,
  weeklyData,
  weeklySchedule,
}) {
  const [formattedAttendanceData, setFormattedAttendanceData] = useState(null);
  const [formattedWeekAttendance, setFormattedWeekAttendance] = useState(null);
  const [loading, setLoading] = useState(true);
  const [displayType, setDisplayType] = useState(0);
  const studentCount = useRef(null);
  const weeklyAttendance = useRef(weeklyData);
  const weeklySessionCount = useRef(null);
  /*
  Display Types:
      0: Display Attendance by student
      1: Display Attendance by Week
      2: Display Attendance by Session
  */
  const mergeSessions = (schedule) => {
    const sessionMap = new Map();
    schedule.forEach((session) => {
      const key = `${session.weekday}-${session.time}-${session.location}`;
      let existingEntry = null;
      for (let [ids, info] of sessionMap.entries()) {
        if (info.key === key) {
          existingEntry = ids;
          break;
        }
      }

      if (existingEntry) {
        const updatedIds = [...existingEntry, session.weeklyID];
        const updatedSAM = `${sessionMap.get(existingEntry).SAM}, ${session.SAM}`;

        sessionMap.set(updatedIds, {
          ...sessionMap.get(existingEntry),
          weeklySessionIDs: updatedIds,
          SAM: updatedSAM,
        });
        sessionMap.delete(existingEntry);
      } else {
        sessionMap.set([session.weeklyID], {
          key: key,
          SAM: session.SAM,
          weekday: session.weekday,
          time: session.time,
          sessionNumber: sessionMap.size + 1,
        });
      }
    });
    return sessionMap;
  };
  useEffect(() => {
    const mergedSchedule = mergeSessions(weeklySchedule);
    const weeklyIDArr = Array.from(mergedSchedule.keys());
    let tableData = [
      <table key={`attendanceTable-${scheduleID}`} className="attendanceTable">
        <thead
          key={`attendanceTableThead-${scheduleID}`}
          className="attendanceTableThead"
        >
          <tr
            key={`attendanceTableMainRow-${scheduleID}`}
            className="attendanceTableRow"
          >
            <th
              key={`attendanceTableWeekHeader-${scheduleID}`}
              className="attendanceTableHeader"
            >
              Weekly Sessions
            </th>
            {getHeaderRow(mergedSchedule)}
          </tr>
        </thead>
        <tbody
          key={`attendanceTableBody-${scheduleID}`}
          className="attendanceTableBody"
        >
          {getWeekRows(weeklyIDArr, weeklyData)}
        </tbody>
      </table>,
    ];
    weeklySessionCount.current = weeklyIDArr.length;
    setFormattedWeekAttendance(tableData);
  }, [weeklySchedule]);

  const getWeekRows = (idArray, attendanceData) => {
    let weekRows = [];
    let currentWeek = 1;

    for (let i = 0; i < attendanceData.length; ) {
      weekRows.push(
        <tr
          key={`attendanceTableRow-${scheduleID}-week${currentWeek}`}
          className="attendanceTableRow"
        >
          <td
            className="attendanceTableData"
            key={`attendanceWeek-${scheduleID}-${currentWeek}`}
          >
            Week {currentWeek}
          </td>
          {getWeekData(
            i,
            idArray,
            attendanceData,
            (newIndex) => (i = newIndex),
          )}
        </tr>,
      );
      currentWeek++;
    }
    return weekRows;
  };
  const getWeekData = (
    sessionStartingIndex,
    idArray,
    attendanceData,
    updateIndex,
  ) => {
    let sessionData = [];
    let studentCount = 0;
    let weekIDIndex = 0;
    let pointer = sessionStartingIndex;

    while (pointer < attendanceData.length && weekIDIndex < idArray.length) {
      let weekIDInner = 0;
      let sessionFound = false;
      let dayStudentCount = 0;

      if (!idArray[weekIDIndex]) {
        sessionData.push(
          <td
            className="attendanceTableData"
            key={`attendanceWeek-${scheduleID}-sessionNum${pointer}`}
          >
            N/A
          </td>,
        );
        pointer++;
        weekIDIndex++;
        continue;
      }

      while (weekIDInner < idArray[weekIDIndex].length) {
        const expectedID = idArray[weekIDIndex][weekIDInner];
        const attendanceRecord = attendanceData[pointer];
        if (attendanceRecord === undefined) break;

        if (
          attendanceRecord &&
          parseInt(attendanceRecord.weeklyID) === parseInt(expectedID)
        ) {
          sessionFound = true;
          dayStudentCount += attendanceRecord.studentCount;
          pointer++;
        }

        weekIDInner++;
      }
      if (sessionFound) {
        sessionData.push(
          <td
            className="attendanceTableData"
            key={`attendanceWeek-${scheduleID}-sessionNum${pointer}-${weekIDIndex}`}
          >
            {dayStudentCount}
          </td>,
        );
        studentCount += dayStudentCount;
      } else {
        sessionData.push(
          <td
            className="attendanceTableData"
            key={`attendanceWeek-${scheduleID}-sessionNum${pointer}-${weekIDIndex}`}
          >
            N/A
          </td>,
        );
      }
      weekIDIndex++;
    }
    updateIndex(pointer);

    sessionData.push(
      <td
        className="attendanceTableData"
        key={`attendanceWeek-weekNum${sessionStartingIndex}-total`}
      >
        {studentCount}
      </td>,
    );

    return sessionData;
  };

  const getHeaderRow = (schedule) => {
    let headers = [];
    for (const [key, value] of schedule) {
      headers.push(
        <th
          key={`attendanceTableWeekHeader-${value.sessionNumber}`}
          className="attendanceTableHeader"
        >
          <span className="weeklyAttendanceSessionSpan">
            {`Session ${value.sessionNumber}`}
          </span>
          <br></br>
          <span className="weeklyAttendanceDaySpan">
            {`${value.weekday} at ${value.time.slice(0, 5)}`}
          </span>
          <br></br>
          <span className="weeklyAttendanceSamSpan">
            Lead By: <span className="samsNamesSpan">{`${value.SAM}`}</span>
          </span>
        </th>,
      );
    }
    headers.push(
      <th
        key={`attendanceTableWeekHeader-totalCount`}
        className="attendanceTableHeader"
      >
        Total:
      </th>,
    );
    return headers;
  };
  //Recreates the attendance table whenever the attendance data is altered as long as the data is not seto to null
  useEffect(() => {
    setLoading(true);
    let attendanceTable = [];
    if (attendanceData !== null && displayType === 0) {
      attendanceTable.push(
        <table
          key={`attendanceTable-${scheduleID}`}
          className="attendanceTable"
        >
          <thead
            key={`attendanceTableThead-${scheduleID}`}
            className="attendanceTableThead"
          >
            <tr
              key={`attendanceTableMainRow-${scheduleID}`}
              className="attendanceTableRow"
            >
              <th
                key={`attendanceTableNameHeader-${scheduleID}`}
                className="attendanceTableHeader"
              >
                Name
              </th>
              <th
                key={`attendanceTableEmailHeader-${scheduleID}`}
                className="attendanceTableHeader"
              >
                Email
              </th>
              <th
                key={`attendanceTableSessionHeader-${scheduleID}`}
                className="attendanceTableHeader"
              >
                Sessions Attended
              </th>
              <th
                key={`attendanceTablePointHeader-${scheduleID}`}
                className="attendanceTableHeader"
              >
                Bonus Points
              </th>
            </tr>
          </thead>
          <tbody
            key={`attendanceTableBody-${scheduleID}`}
            className="attendanceTableBody"
          >
            {getStudentRows()}
          </tbody>
        </table>,
      );
      setFormattedAttendanceData(attendanceTable);
      setLoading(false);
    } else if (displayType === 1) {
      setFormattedAttendanceData(formattedWeekAttendance);
      setLoading(false);
    } else if (displayType === 2) {
    }
  }, [attendanceData, displayType]);

  //Helper function to create the table rows and data for each students attendance
  const getStudentRows = () => {
    let studentRows = [];
    let studentCounter = 0;
    attendanceData.forEach((entry) => {
      studentCounter++;
      studentRows.push(
        <tr
          key={`attendanceTableRow-${scheduleID}-${entry.studentName}`}
          className="attendanceTableRow"
        >
          <td
            className="attendanceTableData"
            key={`attendanceName-${scheduleID}-${entry.studentName}`}
          >
            {entry.studentName}
          </td>
          <td
            className="attendanceTableData"
            key={`attendanceEmail-${scheduleID}-${entry.studentName}`}
          >
            {entry.studentEmail}
          </td>
          <td
            className="attendanceTableData"
            key={`attendanceSessions-${scheduleID}-${entry.studentName}`}
          >
            {entry.sessionCount}
          </td>
          <td
            className="attendanceTableData"
            key={`attendancePoints-${scheduleID}-${entry.studentName}`}
          >
            {entry.pointCount}
          </td>
        </tr>,
      );
    });
    studentCount.current = studentCounter;
    return studentRows;
  };
  const checkFilter = (event) => {
    const options = document.getElementsByClassName(
      "advisorAttendanceSortOption",
    );
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        setDisplayType(parseInt(options[i].value));
        return;
      }
    }
  };
  return (
    <div className="advisorAttendanceSectionWrapper">
      <h2 className="advisorSectionSubheader">Attendance Records</h2>
      <span className="advisorSortByWrapper">
        Display By:
        <select
          defaultValue={displayType}
          onChange={checkFilter}
          className="advisorAttendanceSortSelect"
        >
          <option className="advisorAttendanceSortOption" value={0}>
            Student
          </option>
          <option className="advisorAttendanceSortOption" value={1}>
            Week
          </option>
          <option
            disabled={true}
            className="advisorAttendanceSortOption"
            value={2}
          >
            Session
          </option>
        </select>
      </span>
      {!loading ? (
        <>
          <div className="attendanceTableWrapper">
            {formattedAttendanceData}
          </div>
          {displayType === 0 ? (
            <h3 className="advisorStudentCount">
              Total Students: {studentCount.current}
            </h3>
          ) : null}
        </>
      ) : (
        <h2 className="advisorDashLoadingMessage">Loading...</h2>
      )}
    </div>
  );
}

export default FullAttendance;
