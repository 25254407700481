import React, { useContext, useState } from 'react';
import './Navbar.css';
import * as Fa from 'react-icons/fa';
import * as Ai from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import { IconContext } from 'react-icons';
import homeLogo from "../../assets/homeicon.svg";
import { Context } from '../../routes/App';

const Navbar = ({ courses, selectedCourse, setSelectedCourse, setPage }) => {

    const [sidebar, setSidebar] = useState(false);
    const [userInfo, setUserInfo, manualLogout] = useContext(Context);

    const showSidebar = () => setSidebar(!sidebar);

    const updateSelected = e => {
        setSelectedCourse(e.target.value);
    }

    return (
        <>
            <IconContext.Provider value={{color: '#fff'}}>
                <div className='navbar'>
                    <Link to='#' className='menu-bars'>
                        <Fa.FaBars onClick={showSidebar}/>
                    </Link>
                    <Link to="/">
                        <img
                            id="homeButtonNavLink"
                            src={homeLogo}
                            draggable={false}
                            alt="home"
                        />
                    </Link>
                </div>  
                <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                    <ul className='nav-menu-items' onClick={showSidebar}>
                        <li className='navbar-toggle'>
                            <Link to='#' className='menu-bars'>
                                <Ai.AiOutlineClose />
                            </Link>
                        </li>
                        <li className='nav-text'>
                            <Link>
                              Course ID: CS{selectedCourse}
                            </Link>
                        </li>
                        {SidebarData?.map(({ cName, path, title, icon }, index) => {
                            return (
                                <li key={index} className={cName}>
                                    <Link onClick={() => setPage(path)}>
                                        {icon}
                                        <span style={{ paddingLeft: '10px' }}>{title}</span>
                                    </Link>
                                </li>
                            );
                        })}
                        <li className='nav-text navT'>
                            <Link
                              draggable={false}
                              state={{ logout: true }}
                              onClick={() => manualLogout()}
                            >
                              Log Out
                            </Link>
                        </li>
                        <hr style={{
                            borderColor: 'white',
                        }} />
                        <li className='nav-text'>
                            <Link>
                                Switch Courses
                            </Link>
                        </li>
                        
                        <div className='mx-4'>
                            <select className='form-select' onChange={updateSelected} value={selectedCourse}>
                                {courses?.map((course, idx) => (
                                    <option key={idx} value={course} selected={course === selectedCourse}>
                                        {course}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </ul>
                </nav>
            </IconContext.Provider>
        </>
    )
}

export default Navbar