import React from 'react';
import empty from "../assets/images/empty.svg";

const Empty = ({text}) => {
    return (
        <div className='mt-5' style={{ textAlign: 'center', fontWeight: 'bolder', fontSize: '25px', color: 'black', padding: '0px 10px 20px 10px' }}>
            <h4 className="text-center text-dark mb-5">{text}</h4>
            <img
                src={empty}
                draggable={false}
                alt="Not Found"
                style={{ width: "150px" }}
                className='mt-5'
            />
        </div>
    )
};

export default Empty;