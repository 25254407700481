import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";

function NewProblem({ submitFunction, categories }) {
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [problemMessage, setProblemMessage] = useState(null);
  const [desc, setDesc] = useState("");
  const [name, setName] = useState("");
  const [hint, setHint] = useState("");
  const [comment, setComment] = useState("");
  const [newCategory, setNewCategory] = useState("");

  const submitProblem = () => {
    if (selectedCategory == 0) {
      setProblemMessage("Please select a category");
      return;
    }

    if (!desc) {
      setProblemMessage("Problem Description cannot be empty");
      return;
    }
    if (!name) {
      setProblemMessage("Problem Name cannot be empty");
      return;
    }

    const category = selectedCategory == -1 ? newCategory : selectedCategory;
    if (selectedCategory == -1 && !newCategory) {
      setProblemMessage("Category cannot be empty");
      return;
    }

    const problemInfo = [desc, name, hint || null, comment || null, category];
    submitFunction(problemInfo);
  };

  // Quill editor modules and formats
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline", "strike"],
      [{ align: [] }],
      ["link"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "align",
  ];
  
  useEffect(() => {
    if (problemMessage != null) {
      const timer = setTimeout(() => {
        setProblemMessage(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [problemMessage]);

  console.log(selectedCategory);

  return (
    <div className="container p-4 border rounded">
      <h5 className="mb-4 text-black">Create New Problem</h5>

      {problemMessage && (
        <p className="alert alert-warning mt-3">{problemMessage}</p>
      )}

      <div className="mb-3">
        <label className="form-label">Category</label>
        <select
          className="form-select"
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          <option value={0}>Please select a category</option>
          <option value={-1}>Create new category</option>
          {categories?.map((entry) => (
            <option key={entry} value={entry}>
              {entry}
            </option>
          ))}
        </select>
      </div>

      {selectedCategory == -1 && (
        <div className="mb-3">
          <label className="form-label">New Category</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter new category"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
          />
        </div>
      )}

      <div className="mb-3">
        <label className="form-label">Problem Name</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter problem name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>

      <div className="mb-3">
        <label className="form-label">Description</label>
        <ReactQuill
          theme="snow"
          value={desc}
          onChange={setDesc}
          modules={modules}
          formats={formats}
          className="bootstrap-quill"
        />
      </div>

      <div className="mb-3">
        <label className="form-label">Hint</label>
        <ReactQuill
          theme="snow"
          value={hint}
          onChange={setHint}
          modules={modules}
          formats={formats}
          className="bootstrap-quill"
        />
      </div>

      <div className="mb-3">
        <label className="form-label">Comments</label>
        <ReactQuill
          theme="snow"
          value={comment}
          onChange={setComment}
          modules={modules}
          formats={formats}
          className="bootstrap-quill"
        />
      </div>

      <button className="myBtn" onClick={submitProblem}>
        Create Problem
      </button>
    </div>
  );
}

export default NewProblem;
