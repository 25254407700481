import React, { useState, useEffect } from "react";
import Accordion from 'react-bootstrap/Accordion';

const courses = {
  '2311': "Discrete Structures",
  '2321': "Data Structures",
  '3331': "Concurrent Computing",
  '3411': "Systems Programming",
};

const CourseAttendance = ({ courseAttendanceObj }) => {

    console.log(courseAttendanceObj);
    const [points, setPoints] = useState({});
    useEffect(() => {
        let bonus = {};
        for (let course in courseAttendanceObj) {
            bonus[course] = 0;
            courseAttendanceObj[course]?.forEach(val => {
                bonus[course] += val?.bonusPoints;
            })
        }
        setPoints(bonus);
    }, [courseAttendanceObj]);

    const isEmpty = obj => Object.keys(obj).length === 0;
     
    return (
        <section className="pt-30 pb-30">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="sidebar-widgets sidebar-widgets-style-1">
                        <div className="single-widget categories_widget">
                            <div className="widget-title">
                            <h2><span>Bonus Points Earned</span></h2>
                            </div>
                            <div className="categories-list ">
                            <ul>
                                {!isEmpty(points) ? Object.entries(points)?.map(([key, value]) => {
                                    return (
                                        <li key={key}><a>{courses[key.slice(1,5)] || key.slice(1,5)}: {value} points</a></li>
                                    )
                                }): <li><a>No Points Earned Yet!</a></li>}
                            </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                    <div className="sidebar-widgets sidebar-widgets-style-1">
                        <div className="single-widget categories_widget">
                            <div className="widget-title">
                                <h2><span>Sessions Attended</span></h2>
                                </div>
                                <div className="accordion mt-4 mb-3" id="accordionExample">
                                    <Accordion defaultActiveKey="1">
                                    {Object.entries(courseAttendanceObj)?.map(([key, value], index) => {
                                        return (
                                            <Accordion.Item eventKey={index}>
                                                <Accordion.Header>{courses[key.slice(1,5)] || key.slice(1,5)}</Accordion.Header>
                                                <Accordion.Body>
                                                {value?.length > 0 ? value?.map((val, index) => <p className="p-0 m-0" key={index}>{val?.timestamp}</p>) : <p>No Sessions Attended!</p>}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        );
                                    })}
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CourseAttendance;