import React from "react";

function MultiSelect({ entries, selections }) {
  // Handles 'Enter' key selection/deselection of top item in the list
  const search = (event) => {
    if (event.key === 'Enter') {
      const options = document.getElementsByClassName(`student-option`);
      const text = document.getElementById(`multiSelectSearch1`).value.toLowerCase();
      for (let item of options) {
        if (item.innerHTML.toLowerCase().includes(text)) {
          document.getElementById(`multiSelectSearch1`).value = "";
          toggleSelection({ target: item });
          break;
        }
      }
    }
  };

  // Filters displayed options based on search input
  const filterFunction = () => {
    const options = document.getElementsByClassName(`student-option`);
    const text = document.getElementById(`multiSelectSearch1`).value.toLowerCase();
    for (let item of options) {
      item.style.display = item.innerHTML.toLowerCase().includes(text) ? "" : "none";
    }
  };

  // Toggles selection on click and updates styling
  const toggleSelection = (event) => {
    const id = event.target.id;
    if (selections.current.includes(id)) {
      selections.current = selections.current.filter((item) => item !== id);
      event.target.classList.remove("bg-primary", "text-white");
    } else {
      selections.current.push(id);
      event.target.classList.add("bg-primary", "text-white");
    }
  };

  return (
    <div className="mb-3">
      <input
        type="text"
        id="multiSelectSearch1"
        className="form-control mb-2"
        onKeyUp={filterFunction}
        onKeyDown={search}
        placeholder="Search Students Name"
      />
      <div className="list-group overflow-auto" style={{ maxHeight: '400px' }}>
        {entries.map((entry) => (
          <button
            key={`attendanceSelectEntry-${entry[0]}`}
            id={entry[1]}
            onClick={toggleSelection}
            className={`list-group-item list-group-item-action student-option ${selections.current.includes(entry[1]) ? "bg-primary text-white" : ""}`}
          >
            {entry[0]}
          </button>
        ))}
      </div>
    </div>
  );
}

export default MultiSelect;
