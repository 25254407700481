import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import edit from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import ConfirmationModal from "./ConfirmationModal";

function UpdateSamSays({ currentSamSays, course, refresh }) {
    const [addSamSaysMessage, setAddSamSaysMessage] = useState("");
    const [editSamSaysMessage, setEditSamSaysMessage] = useState("");
    const [selectedQuote, setSelectedQuote] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [message, setMessage] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    // const clearMessage = useRef(null);

    useEffect(() => {
        if (editSamSaysMessage != null || addSamSaysMessage != null) {
          const timer = setTimeout(() => {
            setEditSamSaysMessage(null);
            setAddSamSaysMessage(null);
          }, 10000);
          return () => clearTimeout(timer);
        }
    }, [editSamSaysMessage, addSamSaysMessage]);

    console.log(currentSamSays);

    //Helper function to update sam says
    const submitSamSays = async (newMessage) => {
        try {
            const apiReq = await axios.post(
                `https://api.sam.cs.mtu.edu/api/sam/addSamSays/${course}`, { 
                    message: newMessage,
                    status: 1
                 },
                 { withCredentials: true }
            );
            if (apiReq.status === 200) {
                return "SAM Says has been updated.";
            } else {
                return "Update failed!";
            }
        } catch (error) {
            console.log(error);
        }
    }

    // Helper function to check fields and call the update function
    const addSamSays = () => {
        const newMessage = document.getElementById("samSaysTextField1").value;
        if (newMessage === "") {
            setAddSamSaysMessage("Message must not be empty!");
        } else {
            setAddSamSaysMessage("Processing...");
            const sendUpdate = async () => {
                let response = await submitSamSays(newMessage);
                setAddSamSaysMessage(response);
                document.getElementById("samSaysTextField1").value = "";
                refresh();
            };
            sendUpdate();
        }
    };

    const editSamSays = () => {
        if (!message) {
            alert("Please don't leave field empty");
        } else {
            axios.post(
                `https://api.sam.cs.mtu.edu/api/sam/updateSamSays`, { 
                    id: selectedQuote?.quoteID,
                    message: message,
                    status: isChecked ? 1 : 0
                },
                { withCredentials: true }
            )
            .then((response) => {
                setEditSamSaysMessage("SAM Says has been updated.");
                setMessage("");
                setIsChecked(false);
                setSelectedQuote([]);
                refresh();
            })
            .catch((error) => {
                console.log(error);
                setEditSamSaysMessage("Update failed!");
                setMessage("");
                setIsChecked(false);
                setSelectedQuote([]);
            });
        }
    }

    const deleteSamSays = () => {
        console.log(selectedQuote);
        axios.delete(
            `https://api.sam.cs.mtu.edu/api/sam/deletesamsays/${selectedQuote?.quoteID}`,
             { withCredentials: true }
        )
        .then((response) => {
            setEditSamSaysMessage("SAM Says has been deleted.");
            setMessage("");
            setIsChecked(false);
            setSelectedQuote([]);
            refresh();
        })
        .catch((error) => {
            console.log(error);
            setEditSamSaysMessage("Delete failed!");
            setMessage("");
            setIsChecked(false);
            setSelectedQuote([]);
        });
    }

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const showEdit = (data) => {
        setSelectedQuote(data);
        setShowEditModal(true);
        data?.active == 1 ? setIsChecked(true) : setIsChecked(false);
        setMessage(data?.message);
    }

    /*
        This function will need to be updated with the new prop structure.
        currentSamSays now holds an array of objects, each object has the following:
            quoteID: number
            courseID: course quote is for
            active: 1/0 status represent the boolean active value in the DB
            message: actual quote contents
            Date: date already formatted
            SAM: Full name of sam

        To update SAM Says, pass an array of quote objects back with the following attributes:
            quote.id: Representing the quoteID from above,  if its a new quote set this to -1
            quote.message: Represents new quote message
            quote.status: Boolean 'true/false' to represent active(true) or inactive(false)

        The endpoint requires a post request to be sent to /api/sam/updateSamSays/:courseID, it access the req.body.quoteList and does quoteList.forEach((quote))
        so please try to format your input to match this. 


        To delete a sam says post, a delete request needs to be sent to /api/sam/deleteSamSays/:quoteID

        Both of these requests assume you are also passing in the token cookie
    */

    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-md-4 mb-4">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="my-3 text-left text-dark">Add New Quote</h5>
                        </div>
                        <div className="card-body">
                            {addSamSaysMessage && (
                                <p className="alert alert-info mt-3 text-center">{addSamSaysMessage}</p>
                            )}
                            <>
                                <textarea
                                    id="samSaysTextField1"
                                    // defaultValue={currentSamSays}
                                    className="form-control mb-3"
                                    rows="4"
                                    placeholder="Enter the SAM Says message"
                                ></textarea>
                                <button className="myBtn w-100" onClick={addSamSays}>
                                    Submit
                                </button>
                            </>
                        </div>
                    </div>
                </div>
                <div className="col-md-8 mb-4">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="my-3 text-left text-dark">SAM Says Table</h5>
                        </div>
                        <div className="card-body table-responsive">
                            {editSamSaysMessage && (
                                <p className="alert alert-info mt-3 text-center">{editSamSaysMessage}</p>
                            )}
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Message</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">SAM</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentSamSays[0]?.map((data, idx) => (
                                        <tr>
                                            <td><code>#{++idx}</code></td>
                                            <td>{data?.message}</td>
                                            <td>
                                                {data?.active == 1 ?
                                                    <div className="badge rounded-pill bg-success">Active</div> :
                                                    <div className="badge rounded-pill bg-danger">Inactive</div>
                                                }
                                            </td>
                                            <td>{data?.SAM}</td>
                                            <td>{data?.Date}</td>
                                            <td>
                                                <img
                                                    draggable={false}
                                                    onClick={() => showEdit(data)}
                                                    src={edit}
                                                    className="editIcon"
                                                    style={{ width: "20px", marginRight: "10px" }}
                                                />
                                                <img
                                                    draggable={false}
                                                    onClick={() => {
                                                        setSelectedQuote(data);
                                                        setShowDeleteModal(true)
                                                    }}
                                                    src={deleteIcon}
                                                    className="deleteIcon"
                                                    style={{ width: "20px" }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <ConfirmationModal
                show={showDeleteModal}
                title="Confirm Deletion"
                message="Are you sure you want to delete this quote?"
                onConfirm={() => {
                    deleteSamSays();
                    setShowDeleteModal(false);
                }}
                onCancel={() => setShowDeleteModal(false)}
            />

            {showEditModal && (
                <div className="modal show d-block" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-dark">Edit Quote</h5>
                        </div>
                        <div className="modal-body">
                            <>
                                <textarea
                                    defaultValue={selectedQuote?.message}
                                    className="form-control mb-3"
                                    rows="4"
                                    onChange={(e) => setMessage(e.target.value)}
                                ></textarea>
                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="form-check-label">
                                        Active?
                                    </label>
                                </div>
                            </>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => {
                                    editSamSays();
                                    setShowEditModal(false);
                                }}
                            >
                                Submit
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => setShowEditModal(false)}
                            >
                                Cancel
                            </button>
                        </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default UpdateSamSays;
