import React, { useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import CarouselImage from "./CarouselImage";
import $ from "jquery";
import photo1 from "../assets/homepagePhotos/photo-1.jpg";
import photo2 from "../assets/homepagePhotos/photo-2.jpg";
import photo3 from "../assets/homepagePhotos/photo-3.jpg";
import photo4 from "../assets/homepagePhotos/photo-4.jpg";
import photo5 from "../assets/homepagePhotos/photo-5.jpg";
import photo6 from "../assets/homepagePhotos/photo-6.jpg";
import photo7 from "../assets/homepagePhotos/photo-7.jpg";
import photo8 from "../assets/homepagePhotos/photo-8.jpg";
import photo9 from "../assets/homepagePhotos/photo-9.jpg";
import photo10 from "../assets/homepagePhotos/photo-10.jpg";
import photo11 from "../assets/homepagePhotos/photo-11.jpg";
import photo12 from "../assets/homepagePhotos/photo-12.jpg";

const BannerCarousel = () => {
    return (
        <Carousel>
            <Carousel.Item>
                <CarouselImage image={photo7} text="First slide" />
                <Carousel.Caption>
                  <h3>Welcome to the Student Academic Mentor (SAM) Program</h3>
                  <p>
                    The SAM Program is designed to enrich the academic experience within
                    our CS Department by providing robust peer mentoring and fostering
                    an inclusive learning environment.
                  </p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <CarouselImage image={photo2} text="Second slide" />
                <Carousel.Caption>
                  <h3>Welcome to the Student Academic Mentor (SAM) Program</h3>
                  <p>
                    The SAM Program is designed to enrich the academic experience within
                    our CS Department by providing robust peer mentoring and fostering
                    an inclusive learning environment.
                  </p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <CarouselImage image={photo3} text="Third slide" />
                <Carousel.Caption>
                  <h3>Welcome to the Student Academic Mentor (SAM) Program</h3>
                  <p>
                    The SAM Program is designed to enrich the academic experience within
                    our CS Department by providing robust peer mentoring and fostering
                    an inclusive learning environment.
                  </p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
};

// Here’s an overview of the program
//                     and how it benefits both students seeking support and prospective
//                     SAMs.

export default BannerCarousel;
