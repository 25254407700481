import { useGoogleOneTapLogin } from "@react-oauth/google";
//This component is just used to mount the one tap prompt conditionally
function GoogleOneTap({ success }) {
  useGoogleOneTapLogin({
    onSuccess: (response) => {
      success(response);
    },
    onError: (error) => console.log("Login Failed:", error),
  });
}
export default GoogleOneTap;
