import React, { useState, useRef, useEffect } from 'react';

function SearchBar({ name, entries, placeholderText, selections, setState, selectState, isMulti }) {
  const [selectedOptions, setSelectionOptions] = useState(selections !== null ? selections.current : null);
  const dropdownRef = useRef(null);

  // Helper function to handle Enter key search functionality
  const search = (event) => {
    if (event.key === 'Enter') {
      const options = document.getElementsByClassName(`searchDropdownOption-${name}`);
      const text = (document.getElementById(`searchBarInput-${name}`).value).toLowerCase();
      for (let item of options) {
        if ((item.innerHTML).toLowerCase().indexOf(text) > -1) {
          document.getElementById(`searchBarInput-${name}`).value = "";
          if (isMulti) {
            item.selected = !item.selected;
            toggleSelection(item.value);
            break;
          } else {
            setState(item.value);
            break;
          }
        }
      }
    }
  };

  // Helper function to toggle selection for multi-select
  const toggleSelection = (input) => {
    if (selections.current.indexOf(input) === -1) {
      selections.current.push(input);
    } else {
      selections.current.splice(selections.current.indexOf(input), 1);
    }
    setSelectionOptions([...selections.current]);
  };

  // Helper function to filter options based on search input
  const filterFunction = () => {
    const options = document.getElementsByClassName(`searchDropdownOption-${name}`);
    const text = (document.getElementById(`searchBarInput-${name}`).value).toLowerCase();
    for (let item of options) {
      item.style.display = item.innerHTML.toLowerCase().indexOf(text) > -1 ? "" : "none";
    }
  };

  // Onclick handler for options
  const clickHandler = (event) => {
    if (isMulti) toggleSelection(event.target.value);
    else setState(parseInt(event.target.value));
  };

  // Helper function to preserve dropdown scroll position
  const savePos = (event) => {
    dropdownRef.current.scrollTop = event.target.scrollTop;
  };

  // Generate entries for the dropdown options
  const getEntries = () => {
    let returnArr = [];
    if (!isMulti) returnArr.push(
      <option 
        className={`searchDropdownOption-${name}`} 
        value={0} 
        key={`searchDropdownOption-default`}
      >
        {placeholderText}
      </option>
    );
    entries.forEach(entry => {
      returnArr.push(
        <option 
          className={`searchDropdownOption-${name}`} 
          fullname={entry[0]} 
          value={entry[1]} 
          key={`searchDropdownOption-${entry[1]}`}
        >
          {entry[0]}
        </option>
      );
    });
    return returnArr;
  };

  return (
    <div className="mb-3">
      {/* <input 
        type="text" 
        className="form-control mb-2" 
        placeholder={placeholderText} 
        id={`searchBarInput-${name}`} 
        onKeyUp={filterFunction} 
        onKeyDown={search} 
      /> */}
      {isMulti ? (
        <select
          ref={dropdownRef}
          id="searchSelect"
          multiple
          value={selectedOptions}
          onChange={clickHandler}
          onScroll={savePos}
          className="form-select"
          style={{ height: '200px', overflowY: 'auto' }}
        >
          {entries !== undefined ? getEntries() : null}
        </select>
      ) : (
        <select
          ref={dropdownRef}
          id="searchSelect"
          value={selectState}
          onChange={clickHandler}
          onScroll={savePos}
          className="form-select"
        >
          {entries !== undefined ? getEntries() : null}
        </select>
      )}
    </div>
  );
}

export default SearchBar;
