import React from "react";
import ReactDOM from "react-dom/client";
import App from "./routes/App";
import Homepage from "./routes/Homepage";
import Course from "./routes/Course";
import Account from "./routes/Account";
import Login from "./routes/Login";
import SamDashboard from "./routes/SamDashboard";
import AdvisorDashboard from "./routes/AdvisorDashboard";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { RouterProvider, createHashRouter } from "react-router-dom";
import { StrictMode } from "react";
import axios from "axios";
axios.defaults.withCredentials = true;
import "./Styles/Index.css";
import { AppStateProvider } from "./store/AppStateContext";
const router = createHashRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Homepage />,
      },
      {
        path: "/courses/:courseID",
        element: <Course />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/account",
        element: <Account />,
      },
      {
        path: "/sam",
        element: <SamDashboard />,
      },
      {
        path: "/advisor",
        element: <AdvisorDashboard />,
      },
    ],
  },
]);
ReactDOM.createRoot(document.getElementById("root")).render(
  <StrictMode>
    <AppStateProvider>
      <GoogleOAuthProvider clientId="463860053272-quaab5kcs3hm58kreqf0po2ok602g083.apps.googleusercontent.com">
        <RouterProvider router={router}>
          <App />
        </RouterProvider>
      </GoogleOAuthProvider>
    </AppStateProvider>
  </StrictMode>,
);
