import React from "react";
const classNames = {
  2311: "Discrete Structures",
  2321: "Data Structures",
  3331: "Concurrent Computing",
  3411: "Systems Programming",
};
function SamInfo(sams) {
  console.log(sams)
  //Helper function to render information regarding sams
  const renderSam = () => {
    let returns = [];

    const handleImageError = (event) => {
      event.target.src = "https://api.sam.cs.mtu.edu/defaults/no_photo.jpg";
    };
    for (const [key, value] of sams.sams) {
      returns.push(
        <div className="col-md-6 col-lg-3">
          <div className="team-thumb text-center">
            <figure className="blog-img" >
              <img 
                src={`https://api.sam.cs.mtu.edu/${value.picture}`}
                onError={handleImageError} 
                alt="SAM's Photo"
                // style={{ height: '80px', width: '100%' }}
              />
            </figure>
            <div className="text">
              <h4 className="title"><a>{key}</a></h4>
              <p className="designation th-cl">{value?.desc}</p>
              <ul className="social-meta">
                <li><i className="fa fa-facebook"></i></li>
                <li><i className="fa fa-twitter"></i></li>
                <li><i className="fa fa-behance"></i></li>
                <li><i className="fa fa-youtube"></i></li>
                <li><i className="fa fa-dribbble"></i></li>
              </ul>
            </div>
          </div>
      </div>
      );
    }
    return returns;
  };

  
//   <div key={`samInfoWrapper-${key}`} className="samInfoWrapper">
//   <div key={`samInfoTextWrapper-${key}`} className="samInfoTextWrapper">
//     <h3 key={`samFullNameHeader-${key}`} className="samFullName">
//       {key}
//     </h3>
//     {getSamCourses(value)}
//     <p key={`samDesc-${key}`} className="samFullDescription">
//       {value.desc}
//     </p>
//   </div>
//   <div key={`samInfoSpacer-${key}`} className="samInfoDivider"></div>
//   <div key={`samPhotoWrapper-${key}`} className="samInfoPhotoWrapper">
//     <img
//       key={`samPhoto-${key}`}
//       className="samPhoto"
//       src={`https://api.sam.cs.mtu.edu/${value.picture}`}
//       onError={handleImageError}
//       alt="No Photo"
//       draggable={false}
//     ></img>
//   </div>
// </div>

  //Helper function to get which courses a sam teachers formatted properly
  const getSamCourses = (samMapInfo) => {
    if (samMapInfo.courses.length === 1)
      return (
        <p className="samCourseInfo">
          SAM For {classNames[samMapInfo.courses[0]]}
        </p>
      );
    let spanArr = [];
    for (let i = 0; i < samMapInfo.courses.length; i++) {
      if (i === samMapInfo.courses.length - 1) {
        spanArr.push(
          <span key={`samInfoNoWrapSpan-${i}`} className="samCourseNowrap">
            {classNames[samMapInfo.courses[i]]}
          </span>,
        );
      } else {
        spanArr.push(
          <span key={`samInfoNoWrapSpan-${i}`} className="samCourseNowrap">
            {classNames[samMapInfo.courses[i]]}
          </span>,
        );
        spanArr.push(" and ");
      }
    }
    return (
      <p key={`fullSamInfoPara`} className="samCourseInfo">
        SAM For {spanArr.map((entry) => entry)}
      </p>
    );
  };

  return renderSam();
}

export default SamInfo;
