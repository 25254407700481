import React from "react";
import { useEffect, useState, useRef, useContext } from "react";
import axios from "axios";
import UserProfile from "../userData/UserProfile";
import "../Styles/Advisor.css";
import FullAttendance from "../components/AdvisorDashboardComponents/FullAttendance";
import SearchBar from "../components/SearchBar";
import SessionAttendanceData from "../components/AdvisorDashboardComponents/SessionAttendanceData";
import PracticeProblems from "../components/SAMandAdvisorComponents/PracticeProblems";
import UploadRoster from "../components/AdvisorDashboardComponents/UploadRoster";
import SideNav from "../components/SideNav";
import { Context } from "./App";
function AdvisorDashboard() {
  const [userInfo, setUserInfo] = useContext(Context);
  const [selectedScheduleID, setSelectedScheduleID] = useState(null); //State managing the selected scheduleID(courseID But specific to a semester/year of a course)
  const [courseList, setCourseList] = useState(null); //State to hold the formatted list of courses to select from
  const [attendanceInfo, setAttendanceInfo] = useState(null); //State to hold the attendance information for a given course
  const [sessionList, setSessionList] = useState(null); //State to hold the list of sessions for a selected course
  const [selectedSession, setSelectedSession] = useState(0); //State to hold the selected session in a given course
  const [loading, setLoading] = useState(true);
  const weeklyAttendance = useRef(null);
  const courseID = useRef(null);
  const uploadMessage = useRef(null);
  const weeklySchedule = useRef(null);
  //Helper function to grab attendance info
  const fetchInfo = (message) => {
    if (
      selectedScheduleID !== -1 &&
      selectedScheduleID !== null &&
      selectedScheduleID !== 0
    ) {
      setLoading(true);
      axios
        .get(
          `https://api.sam.cs.mtu.edu/api/advisor/getInfo/${selectedScheduleID}`,
        )
        .then((response) => {
          weeklySchedule.current = response.data.scheduleData;
          weeklyAttendance.current = response.data.weeklyData;
          setAttendanceInfo(response.data.attendanceData);
          setSessionList(
            response.data.sessionData.map((session) => [
              session.sessionDate,
              session.sessionID,
            ]),
          );
          // setSelectedSession(0);
          setLoading(false);
        })
        .catch((error) => console.log(error));
    }
  };
  //Fetch attendance data + session list when course is selected
  useEffect(() => {
    fetchInfo(null);
    if (courseList !== null) {
      courseList.forEach((entry) => {
        if (parseInt(entry.scheduleID) === selectedScheduleID)
          courseID.current = entry.courseID;
      });
    }
  }, [selectedScheduleID]);

  //Fetch course list on load
  useEffect(() => {
    axios
      .get("https://api.sam.cs.mtu.edu/api/advisor/getcourses")
      .then((response) => {
        if (response.data.returnArr.length > 1) {
          setCourseList(response.data.returnArr);
        } else if (response.data.returnArr.length === 0) {
          setSelectedScheduleID(-1);
        } else {
          setCourseList(response.data.returnArr);
          setSelectedScheduleID(response.data.returnArr[0].scheduleID);
        }
      })
      .catch((error) => console.log(error));
  }, []);
  //onClick action for course selection- updates selectedScheduleID state with selection
  const checkSelection = (event) => {
    if (parseInt(event.target.value) === 0) {
      setSelectedScheduleID(null);
    } else {
      setSelectedScheduleID(parseInt(event.target.value));
    }
  };

  //Helper function to create header + selections for courses
  const getCourseSelector = () => {
    return (
      <>
        <h2 className="advisorDashSubheader">Select a Course</h2>
        <select onChange={checkCourse} className="advisorCourseSelector">
          <option
            className="advisorCourseSelectorOption"
            value={0}
            onClick={checkSelection}
            key="advisorCourseSelectorDefault"
          >
            Please Select a Course
          </option>
          {getCourses()}
        </select>
      </>
    );
  };

  //Checks course selection
  const checkCourse = () => {
    const courses = document.getElementsByClassName(
      "advisorCourseSelectorOption",
    );
    for (let i = 0; i < courses.length; i++) {
      if (courses[i].selected && courses[i].value !== 0) {
        setSelectedScheduleID(parseInt(courses[i].value));
      }
    }
  };
  //Helper function to create option for each course with proper key,value, and labels
  const getCourses = () => {
    let returnSet = [];
    courseList.forEach((course) => {
      if (course.section !== null)
        returnSet.push(
          <option
            className="advisorCourseSelectorOption"
            value={course.scheduleID}
            onClick={checkSelection}
            key={`advisorCourseSelector${course.courseID}`}
          >
            CS{course.courseID}- {course.semester} {course.year} Section:{" "}
            {course.section}
          </option>,
        );
      if (course.section === null)
        returnSet.push(
          <option
            className="advisorCourseSelectorOption"
            value={course.scheduleID}
            onClick={checkSelection}
            key={`advisorCourseSelector${course.courseID}`}
          >
            CS{course.courseID}- {course.semester} {course.year}
          </option>,
        );
    });
    return returnSet;
  };

  const scrollTo = (sectionIndex) => {
    const elements = document.getElementsByClassName("advisorSectionWrapper");
    elements[sectionIndex].scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };
  //Gets all the key components for the advisor dashboard- only ran once selection for course is made and api request is fulfilled
  const getFields = () => {
    const elementMap = [
      "Full Attendance",
      "Session Attendance",
      "Update Course Roster",
      "View Practice Problems",
    ];
    return (
      <>
        <div className="advisorSectionWrapper">
          <FullAttendance
            attendanceData={attendanceInfo}
            scheduleID={selectedScheduleID}
            weeklyData={weeklyAttendance.current}
            weeklySchedule={weeklySchedule.current}
          />
        </div>
        <div className="advisorSectionWrapper">
          <h2 className="advisorSectionSubheader">Session Attendance Data</h2>
          <SearchBar
            name={"sessions"}
            key={`searchBarFor-${selectedScheduleID}-${selectedSession}`}
            entries={sessionList}
            placeholderText={"Please select a session"}
            selections={null}
            setState={setSelectedSession}
            selectState={selectedSession}
            isMulti={false}
          />
          {selectedSession !== 0 ? (
            <SessionAttendanceData
              sessionID={selectedSession}
              selectedCourse={selectedScheduleID}
            />
          ) : null}
        </div>
        <div className="advisorSectionWrapper">
          <UploadRoster
            key={`uploadRosterFor-${selectedScheduleID}`}
            uploadFunction={uploadRosterAPI}
            defaultMessage={uploadMessage.current}
          />
        </div>
        <div className="advisorSectionWrapper">
          <PracticeProblems
            whom={"advisor"}
            selectedCourse={courseID.current}
            key={`practiceProblems-${courseID.current}`}
          />
        </div>
        <SideNav
          navItemsMap={elementMap}
          scrollFunction={scrollTo}
          courseList={courseList}
          updateCourse={changeCourse}
          currentCourse={selectedScheduleID}
        />
      </>
    );
  };
  const changeCourse = (scheduleID) => {
    setSelectedScheduleID(scheduleID);
  };
  const uploadRosterAPI = (form) => {
    axios
      .post(
        `https://api.sam.cs.mtu.edu/api/advisor/uploadroster/${selectedScheduleID}`,
        form,
        {
          headers: { "Content-Type": "multipart/form-data" },
        },
      )
      .then((response) => {
        console.log(response);
        uploadMessage.current = "Upload Complete!";
      })
      .catch((error) => {
        uploadMessage.current = "Upload Failed!";
        console.log(error);
      });
  };
  const getWelcome = () => {
    let returnSet = [];
    returnSet.push(
      <div key={`advisorDashWelcomeWrapper`} className="welcomeWrapper">
        <h1 className="welcomeMessage">Welcome Back {UserProfile.getName()}</h1>
      </div>,
    );
    return returnSet;
  };
  return (
    <div className="advisorDashboardWrapper">
      {!userInfo.Signin ? <Navigate to="/login" /> : getWelcome()}
      {selectedScheduleID === null ? (
        courseList !== null && courseList.length > 1 ? (
          getCourseSelector()
        ) : (
          <h2 className="advisorDashSubheader">Loading...</h2>
        )
      ) : null}
      {courseList !== null && selectedScheduleID !== null ? ( //Courses loaded and selection was made
        selectedScheduleID === -1 ? (
          <h2 className="advisorDashSubheader">
            You have not been added to any courses
          </h2>
        ) : loading ? (
          <h2 className="advisorDashSubheader">Loading... Please wait</h2>
        ) : selectedScheduleID !== 0 ? (
          getFields()
        ) : null
      ) : null}
    </div>
  );
}

export default AdvisorDashboard;
