import React, { useState, useEffect, useRef } from "react";
import "../../Styles/sam.css";
import edit from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import check from "../../assets/check.svg";
import cancel from "../../assets/cancel.svg";
import ConfirmationModal from "./ConfirmationModal";
function WeeklySessions({
  scheduleInfo,
  weeklySessionAdder,
  weeklySessionUpdater,
  weeklySessionDeleter,
  currentCourse,
  setCurrentCourse,
  tableData,
}) {
  const [sessionMap, setSessionMap] = useState(new Map());
  const [currentSession, setCurrentSession] = useState(0);
  const [sessionList, setSessionList] = useState(null);
  const [sessionMessage, setSessionMessage] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const messageTimeout = useRef(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [sessionToDelete, setSessionToDelete] = useState(null);

  useEffect(() => {
    if (sessionMessage !== null) {
      clearTimeout(messageTimeout.current);
      messageTimeout.current = setTimeout(() => {
        setSessionMessage(null);
      }, 10000);
    }
  }, [sessionMessage]);
  //Resetting session message on course change
  useEffect(() => {
    setSessionMessage(null);
  }, [currentCourse]);

  //Get sessions into a map for easy data access
  const getSessions = () => {
    let sessionMapTemp = new Map();
    scheduleInfo[1]?.forEach((session) => {
      if (!sessionMapTemp.has(session.courseID)) {
        sessionMapTemp.set(session.courseID, new Map());
      }
      const weekdayMap = sessionMapTemp.get(session.courseID);
      if (!weekdayMap.has(session.weekday)) {
        weekdayMap.set(session.weekday, []);
      }
      weekdayMap.get(session.weekday).push({
        weeklySessionID: session.weeklySessionID,
        location: session.sessionLocation,
        time: session.sessionTime,
        endtime: session.endTime,
      });
    });
    setSessionMap(sessionMapTemp);
  };

  //Helper function to get time in readable format
  const getFormattedTime = (time) => {
    let [hours, minutes] = time.split(":");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${ampm}`;
  };

  //Updates the course entries whenever there is new schedule info passed in
  useEffect(() => {
    getSessions();
    setCurrentSession(0);
  }, [scheduleInfo]);
  useEffect(() => {
    getWeeklySessionItems();
  }, [sessionMap]);
  const updateDay = (event) => {
    if (selectedDay === event.target.value) {
      event.target.classList.toggle("selectedScheduleDay");
      setSelectedDay(null);
    } else {
      const organizedSessions = sessionMap;
      const weekdays = organizedSessions.get(parseInt(currentCourse));
      if (weekdays.has(event.target.value)) {
        toggleDay(selectedDay);
        setSelectedDay(event.target.value);
      }
    }
  };
  const toggleDay = (weekday) => {
    let options = document.getElementsByClassName("samCourseScheduleDay");
    for (let i = 0; i < options.length; i++) {
      let currentSelection = options[i];
      if (currentSelection.value === weekday) {
        currentSelection.classList.toggle("selectedScheduleDay");
      }
    }
  };
  useEffect(() => {
    if (selectedDay !== null) {
      toggleDay(selectedDay);
    }
  }, [selectedDay]);

  //Helper function to reset the session dropdown properly
  const resetSessionSelection = (message) => {
    setCurrentSession(0);
    setSessionMessage(message);
  };

  const confirmDeleteSession = (sessionID) => {
    setSessionToDelete(sessionID);
    setShowDeleteModal(true);
  };

  const deleteWeeklySession = () => {
    if (sessionToDelete !== null) {
      weeklySessionDeleter(sessionToDelete);
      resetSessionSelection("Session Deleted!");
      setShowDeleteModal(false);
      setSessionToDelete(null);
    }
  };

  // const deleteWeeklySession = () => {
  //   for (const sessionOption of document.getElementById(
  //     "weeklySessionSelectionParent",
  //   ).children) {
  //     if (sessionOption.selected) {
  //       weeklySessionDeleter(sessionToDelete);
  //       resetSessionSelection("Session Deleted!");
  //       setShowDeleteModal(false);
  //       setSessionToDelete(null);
  //     }
  //   }
  // };

  //Helper function to get all of the sessions for the dropdown box
  const getSessionEntries = () => {
    let returnSet = [];
    if (sessionMap.get(parseInt(currentCourse)) !== undefined) {
      const mapIterator = sessionMap
        .get(parseInt(currentCourse))
        [Symbol.iterator]();
      for (const session of mapIterator) {
        session[1].forEach((entry) => {
          let currentID = entry.weeklySessionID;
          let selected = false;
          if (currentSession === parseInt(entry.weeklySessionID))
            selected = true;
          returnSet.push(
            <li
              sessionid={entry.weeklySessionID}
              key={entry.weeklySessionID}
              className="weeklyCourseSessionOption"
            >
              <p
                className={`sessionImmutableInfo ${selected ? "doNotShow" : ""}`}
                style={{ margin: "0px" }}
              >
                {" "}
                {`${session[0]} at ${getFormattedTime(entry.time.slice(0, 5))} to ${getFormattedTime(entry.endtime)} in ${entry.location}`}
              </p>
              <span
                className={`weeklySessionListTextWrapper ${selected ? "" : "doNotShow"}`}
              >
                <span className="weeklySessionWeekdaySelection">
                  <select
                    disabled={selected !== true}
                    defaultValue={session[0]}
                    placeholder="hi"
                    id={`sessionWeekdaySelectionFor${currentID}`}
                    className={`sessionWeekdaySelectionInline ${selected ? "" : "notEditing"}`}
                    key={`weekday4-${currentID}-${selected}`}
                  >
                    {selected === true ? (
                      <>
                        <option
                          className="sessionWeekdaySelectionOption"
                          value="Monday"
                        >
                          Monday
                        </option>
                        <option
                          className="sessionWeekdaySelectionOption"
                          value="Tuesday"
                        >
                          Tuesday
                        </option>
                        <option
                          className="sessionWeekdaySelectionOption"
                          value="Wednesday"
                        >
                          Wednesday
                        </option>
                        <option
                          className="sessionWeekdaySelectionOption"
                          value="Thursday"
                        >
                          Thursday
                        </option>
                        <option
                          className="sessionWeekdaySelectionOption"
                          value="Friday"
                        >
                          Friday
                        </option>
                        <option
                          className="sessionWeekdaySelectionOption"
                          value="Saturday"
                        >
                          Saturday
                        </option>
                        <option
                          className="sessionWeekdaySelectionOption"
                          value="Sunday"
                        >
                          Sunday
                        </option>
                      </>
                    ) : (
                      <option
                        className="sessionWeekdaySelectionOption"
                        value={`${session[0]}`}
                      >
                        {session[0]}
                      </option>
                    )}
                  </select>
                </span>
                <span
                  className={`weeklySessionTimeSelection ${selected ? "" : "notEditing"}`}
                >
                  {" at "}
                  <input
                    disabled={selected !== true}
                    type="time"
                    id={`sessionTimeFieldFor${currentID}`}
                    className={`weeklySessionTime ${selected ? "" : "notEditing"}`}
                    defaultValue={entry.time.slice(0, 5)}
                    step={60}
                    key={`timeUpdate-${currentID}-${selected}`}
                  />
                </span>
                <span
                  className={`weeklySessionTimeSelection ${selected ? "" : "notEditing"}`}
                >
                  {" to "}
                  <input
                    disabled={selected !== true}
                    type="time"
                    id={`sessionEndTimeFieldFor${currentID}`}
                    className={`weeklySessionTime ${selected ? "" : "notEditing"}`}
                    defaultValue={entry.endtime.slice(0, 5)}
                    step={60}
                    key={`timeUpdate-${currentID}-${selected}`}
                  />
                </span>
                <span className="weeklySessionLocationSelection">
                  {" in "}
                  <input
                    disabled={selected !== true}
                    type="text"
                    key={`locationUpdate-${currentID}-${selected}`}
                    id={`weeklySessionLocationFor${currentID}`}
                    className={`weeklySessionLocation ${selected ? "" : "notEditing"}`}
                    defaultValue={entry.location}
                    placeholder="Enter session location"
                  />
                </span>
              </span>

              <span className="weeklySessionIconWrapper">
                <img
                  draggable={false}
                  onClick={() => {
                    if (!selected) {
                      setCurrentSession(
                        parseInt(event.target.getAttribute("value")),
                      );
                    } else {
                      updateSession();
                    }
                  }}
                  value={currentID}
                  src={!selected ? edit : check}
                  className="editIcon"
                  style={{ width: "20px" }}
                ></img>
                {/* Cancel Icon - Only exits edit mode */}
                {selected && (
                  <img
                    draggable={false}
                    onClick={() => setCurrentSession(0)} // Exit edit mode
                    src={cancel}
                    className="cancelIcon"
                    style={{ width: "20px" }}
                  />
                )}

                {/* Delete Icon - Only shows when not in edit mode */}
                {!selected && (
                  <img
                    draggable={false}
                    onClick={() => confirmDeleteSession(currentID)}
                    src={deleteIcon}
                    className="deleteIcon"
                    style={{ width: "20px" }}
                  />
                )}
              </span>
            </li>,
          );
        });
      }
    }
    if (currentSession === 1) {
      returnSet.push(
        <li
  className="list-group-item d-flex flex-wrap align-items-center justify-content-center justify-content-md-start"
  key={`weeklyCourseSessionNewFields`}
>
  <div className="col-12 col-md-auto mb-2 mb-md-0">
    <select className="form-select" id="sessionWeekdaySelectionNew1" defaultValue="Monday">
      {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day) => (
        <option key={day} value={day}>{day}</option>
      ))}
    </select>
  </div>

  <span className="col-12 col-md-auto mb-2 mb-md-0 mx-md-2">From</span>
  <div className="col-12 col-md-auto mb-2 mb-md-0">
    <input type="time" id="weeklySessionTimeNew1" className="form-control" placeholder="Start Time" />
  </div>

  <span className="col-12 col-md-auto mb-2 mb-md-0 mx-md-2">To</span>
  <div className="col-12 col-md-auto mb-2 mb-md-0">
    <input type="time" id="weeklySessionEndTimeNew1" className="form-control" placeholder="End Time" />
  </div>

  <span className="col-12 col-md-auto mb-2 mb-md-0 mx-md-2">At</span>
  <div className="col-12 col-md-auto mb-2 mb-md-0">
    <input type="text" id="weeklySessionLocationNew1" className="form-control" placeholder="Location" />
  </div>

  <div className="col-12 col-md-auto d-flex gap-2 mt-3 mt-md-0">
    <button className="btn btn-link p-0" onClick={createSession}>
      <img src={check} alt="Add" style={{ width: "24px" }} />
    </button>
    <button className="btn btn-link p-0" onClick={() => setCurrentSession(null)}>
      <img src={cancel} alt="Cancel" style={{ width: "24px" }} />
    </button>
  </div>
</li>
,
      );
    } else {
      returnSet.push(
        <li key="add-session" className="list-group-item text-left">
          <button className="btn btn-outline-primary" onClick={() => setCurrentSession(1)}>+ Add New Session</button>
        </li>,
      );
    }
    return returnSet;
  };

  const cancelOrDelete = (event) => {
    let value = parseInt(event.target.getAttribute("value"));
    if (value === currentSession) {
      setCurrentSession(null);
    } else {
      weeklySessionDeleter(value);
      resetSessionSelection("Session Deleted!");
    }
  };

  //Generating list of sessions to select to update
  const getWeeklySessionItems = () => {
    setSessionList(
      <ul className="weeklySessionOptions">{getSessionEntries()}</ul>,
    );
  };

  useEffect(() => {
    getWeeklySessionItems();
  }, [currentSession]);

  const getFields = (sessionID) => {
    if (sessionID === null) {
      const weekday = document.getElementById("sessionWeekdaySelectionNew1")
        .selectedOptions[0].value;
      const time = document.getElementById("weeklySessionTimeNew1").value;
      const location = document.getElementById(
        "weeklySessionLocationNew1",
      ).value;
      const endTime = document.getElementById("weeklySessionEndTimeNew1").value;
      return [weekday, time, location, endTime];
    } else {
      const weekday = document.getElementById(
        `sessionWeekdaySelectionFor${sessionID}`,
      ).selectedOptions[0].value;
      const time = document.getElementById(
        `sessionTimeFieldFor${sessionID}`,
      ).value;
      const location = document.getElementById(
        `weeklySessionLocationFor${sessionID}`,
      ).value;
      const endTime = document.getElementById(
        `sessionEndTimeFieldFor${sessionID}`,
      ).value;
      return [weekday, time, location, sessionID, endTime];
    }
  };
  const updateSession = () => {
    const clickedSessionID = parseInt(event.target.getAttribute("value"));
    const values = getFields(clickedSessionID);
    if (values[1].length === 0 || values[2].length === 0) {
      setSessionMessage("Please fill out all fields");
      return;
    } else {
      weeklySessionUpdater(values);
    }
  };
  const getSessionList = () => {
    return sessionList;
  };
  const createSession = () => {
    const newFields = getFields(null);
    if (newFields[1].length === 0 || newFields[2].length === 0) {
      setSessionMessage("Please fill out all fields");
      return;
    } else {
      weeklySessionAdder(newFields);
      resetSessionSelection("Session Added!");
    }
  };
  return (
    <div className="col-12 col-lg-6 col-md-8">
      <h5 className="text-left text-dark mb-3">Your Weekly Schedule</h5>
      <div className="card">
        {sessionList !== null ? getSessionList() : null}
        {/* <div key="updateMessage" className="updateSessionMessageDiv">
          {sessionMessage ? <p className="alert alert-info mt-3 text-center">{sessionMessage}</p> : ""}
        </div> */}
      </div>

      <ConfirmationModal
        show={showDeleteModal}
        title="Confirm Deletion"
        message="Are you sure you want to delete this session?"
        onConfirm={deleteWeeklySession}
        onCancel={() => setShowDeleteModal(false)}
      />
    </div>
  );
}

export default WeeklySessions;