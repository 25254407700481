import React from "react";
import check from "../../assets/check.svg";

function StudentEntry({
  studentName,
  studentUsername,
  bonusPointCount,
  updatePoints,
}) {
  const updateStudentPoints = () => {
    const username = studentUsername;
    const bonusPoints = parseInt(
      document.getElementById(`bonusPointsNumberInput-${username}`).value,
    );
    updatePoints(username, bonusPoints);
  };

  const checkNumber = (event) => {
    if (isNaN(parseInt(event.target.value))) {
      event.target.value = 0;
    }
  };

  return (
    <div className="d-flex align-items-center mb-3 p-2 border rounded">
      <div className="flex-grow-1">
        <span className="fw-bold">{studentName}</span>
      </div>
      <div className="d-flex align-items-center ms-3">
        <span className="me-2">Bonus Points:</span>
        <input
          type="number"
          id={`bonusPointsNumberInput-${studentUsername}`}
          className="form-control form-control-sm me-2"
          defaultValue={bonusPointCount}
          min={0}
          step={1}
          onInput={checkNumber}
          style={{ width: "70px" }}
        />
        <button className="btn btn-link p-0" onClick={updateStudentPoints}>
          <img src={check} alt="Submit" style={{ width: "20px" }} />
        </button>
      </div>
    </div>
  );
}

export default StudentEntry;
