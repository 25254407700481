import axios from "axios";
const UserProfile = (function () {
  let fullName = localStorage.getItem("name") || "";
  let roles = localStorage.getItem("roles")
    ? JSON.parse(localStorage.getItem("roles"))
    : {
        Student: false,
        SAM: false,
        Advisor: false,
        Admin: false,
      };

  const getName = () => {
    return fullName;
  };

  const getRoles = () => {
    return roles;
  };

  const setRoles = (newRoles) => {
    roles = { ...roles, ...newRoles };
    localStorage.setItem("roles", JSON.stringify(roles));
  };

  const registerUser = (name, userRoles = {}) => {
    localStorage.clear();
    setName(name);
    setRoles(userRoles);
  };

  const setName = (name) => {
    fullName = name;
    localStorage.setItem("name", name);
  };

  const logOut = () => {
    localStorage.clear();
    fullName = "";
    roles = {
      Student: false,
      SAM: false,
      Advisor: false,
      Admin: false,
    };
  };

  const isLoggedIn = async () => {
    try {
      const response = await axios.get(
        "https://api.sam.cs.mtu.edu/api/auth/check-session",
        { withCredentials: true },
      );
      if (response.status === 200) {
        return true;
      } else {
        logOut();
        return false;
      }
    } catch (error) {
      logOut();
      return false;
    }
  };

  return {
    getName,
    getRoles,
    setName,
    setRoles,
    registerUser,
    isLoggedIn,
    logOut,
  };
})();

export default UserProfile;
