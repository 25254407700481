import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from '../components/Dashboard/Navbar';
import '../components/Dashboard/Navbar.css';
import axios from "axios";
import { Context } from './App';
import UserProfile from "../userData/UserProfile";
import UpdateProfile from "../components/SAMDashComponents/UpdateProfile";
import PracticeProblems from "../components/SAMandAdvisorComponents/PracticeProblems";
import UpdateSamSays from "../components/SAMDashComponents/UpdateSamSays";
import ViewAskSAM from "../components/SAMDashComponents/ViewAskSAM";
import FullCourseSchedule from "../components/SAMDashComponents/FullCourseSchedule";
import CourseSchedule from "../components/CourseComponents/CourseSchedule";
import WeeklySessions from "../components/SAMDashComponents/WeeklySessions";
import CancelSession from "../components/SAMDashComponents/CancelSession";
import NonWeeklySession from "../components/SAMDashComponents/NonWeeklySession";
import empty from "../assets/images/empty.svg";
import Empty from "../components/Empty";
import { useAppState } from "../store/AppStateContext";
import FileSharing from "../components/SAMDashComponents/FileSharing";
import { GiConsoleController } from "react-icons/gi";
import UpdateAttendance from "../components/SAMDashComponents/UpdateAttendance";

const SamDashboard = () => {

    const [userInfo, setUserInfo] = useContext(Context);
    const [profile, setProfile] = useState([]);
    const {selectedCourse, setSelectedCourse} = useAppState();
    const [courses, setCourses] = useState([]);
    const [page, setPage] = useState('');

    const [samSchedule, setSamSchedule] = useState([]);
    const [courseSelections, setCourseSelections] = useState();
    const [selectedCourseStudents, setSelectedCourseStudents] = useState([]);
    const [currentSamSays, setCurrentSamSays] = useState(null);
    const [samSaysMessage, setSamSaysMessage] = useState(null);
    const [askSamEntries, setAskSamEntries] = useState(null);
    const [scheduleInfo, setScheduleInfo] = useState(null);

    const getScheduleData = () => {
        axios
          .get("https://api.sam.cs.mtu.edu/api/sam/getscheduleinfo")
          .then((response) => {
            let sessionVals = Object.values(response.data[0]); //This only includes non-reoccuring sessions
            let weeklyVals = Object.values(response.data[1]); //This only includes reoccuring sessions
            let coursesTemp = Object.values(response.data[2]); //This is a list of all courses the requestor is a SAM for
            let allSessions = Object.values(response.data[3]); //This includes non-reoccuring and reoccuring sessions
            let courseVals = [];
            console.log(response?.data);
            coursesTemp.forEach((entry) => courseVals.push(parseInt(entry.courseID)));
            setCourses(courseVals);
            selectedCourse == 0 ? setSelectedCourse(courseVals[0]) : null;
            let setArr = [sessionVals, weeklyVals, courseVals, allSessions];
            setSamSchedule(setArr);
            dataFetch();
          })
          .catch((error) => console.log(error));
    };

    //Helper function to get the ask sam data
    const fetchAskSam = () => {
      axios
        .get(`https://api.sam.cs.mtu.edu/api/sam/asksams/${selectedCourse}`)
          .then((response) => {
              console.log(response);
            if (response.data.length !== 0) {
            setAskSamEntries(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const dataFetch = () => {
        if (selectedCourse !== 0) {
          let p1 = axios.get(
            `https://api.sam.cs.mtu.edu/api/sam/getStudents/${selectedCourse}`,
          );
          let p2 = axios.get(
            `https://api.sam.cs.mtu.edu/api/course/samsays/${selectedCourse}`,
          );
          let p3 = axios.get(
            `https://api.sam.cs.mtu.edu/api/sam/fullSchedule/${selectedCourse}`,
          );
          fetchAskSam();
          Promise.all([p1, p2, p3]).then((response) => {
            setSelectedCourseStudents(response[0].data.returnArr);
            if (response[1].data.length===0) {
              setCurrentSamSays("No SAM Says for this course yet");
            } else {
                //This log will show you the new formatting of the sam says data, uncomment if you need this
                //console.log(response[1].data);
              setCurrentSamSays(response[1].data);
            }
            setScheduleInfo(response[2].data.response[0]);
          });
        }
    }

    useEffect(() => {
        dataFetch();
    }, [selectedCourse]);

    useEffect(() => {
        getScheduleData();
    }, []);
    

    //Helper function to add a weekly sessionList
    const addWeeklySession = (info) => {
        axios
        .post("https://api.sam.cs.mtu.edu/api/sam/addWeeklySession", {
            weekday: info[0],
            time: info[1],
            location: info[2],
            courseID: selectedCourse,
            endtime: info[3],
        })
        .then(() => {
            getScheduleData();
        })
        .catch((error) => {
            console.log(error);
        });
    };

    //Helper function to update a weekly sessionList
    const updateWeeklySession = (info) => {
        axios
        .patch("https://api.sam.cs.mtu.edu/api/sam/updateWeeklySession", {
            weekday: info[0],
            weeklySessionID: info[3],
            time: info[1],
            location: info[2],
            endtime: info[4],
        })
        .then(() => {
            getScheduleData();
        })
        .catch((error) => console.log(error));
    };

    //Helper function to delete a weekly sessionList
    const deleteWeeklySession = (id) => {
        axios
        .delete(`https://api.sam.cs.mtu.edu/api/sam/deleteWeeklySession/${id}`)
        .then(() => {
            getScheduleData();
        })
        .catch((error) => {
            console.log(error);
        });
    };

    //Helper function to add a sessionList
    const addSession = (sessionInfo) => {
        axios
        .post("https://api.sam.cs.mtu.edu/api/sam/addsession", {
            courseID: selectedCourse,
            sessionDate: sessionInfo[0],
            sessionTime: sessionInfo[1],
            sessionLocation: sessionInfo[2],
            sessionNotes: sessionInfo[3],
            endtime: sessionInfo[4],
        })
        .then((response) => {
            getScheduleData();
        })
        .catch((error) => {
            console.log(error);
        });
    };

    //Helper function to update a sessionList
    const updateSession = (params) => {
        axios
        .patch("https://api.sam.cs.mtu.edu/api/sam/updatesession", {
            date: params[0],
            time: params[1],
            location: params[2],
            notes: params[3],
            endtime: params[4],
            sessionID: params[5],
        })
        .then(() => {
            getScheduleData();
            return 0;
        })
        .catch((error) => {
            console.log(error);
            return 1;
        });
    };

    //Helper function to delete a sessionList
    const deleteSession = (sessionID) => {
      axios
        .delete(`https://api.sam.cs.mtu.edu/api/sam/deletesession/${sessionID}`)
        .then(() => getScheduleData())
        .catch((error) => console.log(error));
    };
  
    //Helper function to 'cancel'(delete from db) a specific session
    const cancelSession = (sessionID) => {
      return axios.delete(
        `https://api.sam.cs.mtu.edu/api/sam/deletesession/${sessionID}`,
      );
      //Cancel logic goes here
    };

    useEffect(() => {
        axios
            .get("https://api.sam.cs.mtu.edu/api/sam/myprofile")
            .then((response) => {
                setProfile(Object.values(response.data));
            })
            .catch((error) => console.log(error));
    }, []);

    console.log(profile);

    return (
        <div className="App">
            <Navbar
                courses={courses}
                selectedCourse={selectedCourse}
                setSelectedCourse={setSelectedCourse}
                setPage={setPage}
            />
            <div className="dash-content">
                {(page == '' || page == 'home') ?
                    !userInfo.Signin ?
                        <Navigate to="/login" /> :
                        <div className="row m-5">
                            <div className="col-12 col-lg-4 col-md-6">
                                <div className="card radius-15" style={{background: "#ffcc00"}}>
                                    <div className="card-body text-center">
                                        <div className="d-flex flex-column align-items-center justify-content-center p-4 radius-15">
                                            <div className="rounded-circle shadow bg-white d-flex align-items-center justify-content-center" style={{ width: "150px", height: "150px", overflow: "hidden" }}>
                                                <img
                                                    src={`https://api.sam.cs.mtu.edu/${profile[1]}`}
                                                    className="w-100 h-100"
                                                    style={{ objectFit: "cover" }}
                                                    alt="Profile Pic"
                                                />
                                            </div>
                                            <h5 className="mb-0 mt-5 text-dark text-center">Welcome back, {UserProfile.getName()}</h5>
                                            <p className="mb-3 text-dark text-center">Student Academic Mentor</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    : ''
                }
                {(page == 'profile') ? <UpdateProfile /> : ''}
                {(page == 'practice') ?
                    <div className="px-3">
                        <PracticeProblems
                            selectedCourse={selectedCourse}
                            whom={"sam"}
                        />
                    </div>
                    : ''
                }
                {(page == 'samsays') ?
                    <UpdateSamSays
                        currentSamSays={currentSamSays}
                        course={selectedCourse}
                        refresh={dataFetch}
                    />
                    : ''
                }
                {(page == 'attendance') ?
                    <div className="px-3 mb-4">
                        <UpdateAttendance
                            sessions={samSchedule[3]}
                            selectedCourse={selectedCourse}
                            studentList={selectedCourseStudents}
                        />
                    </div> : ''}
                {(page == 'asksams') ? askSamEntries ?
                    <div className="px-3">
                        <ViewAskSAM
                        entries={askSamEntries}
                    />
                    </div>
                    : <Empty text="View Ask SAM Questions" />
                    : ''
                }
                {(page == 'sessions') ? scheduleInfo?.length > 0 ?
                    <div className="px-3">
                        <h4 className="text-center mb-5 text-dark mt-5">Weekly Sessions</h4>
                        <div className="container card border-0 p-4 my-5">
                            <CourseSchedule
                                courseID={null}
                                scheduleData={scheduleInfo}
                            />
                        </div>
                    </div>
                    : <Empty text="Weekly Sessions" />
                    : ''
                }
                {(page == 'newsessions') ?
                    <div
                        className="samDashSection scheduleSection px-3 mb-4"
                    >
                        <h4 className="text-center text-dark mt-5">Manage SAM Sessions</h4>
                        <WeeklySessions
                            scheduleInfo={[samSchedule[2], samSchedule[1]]}
                            weeklySessionAdder={addWeeklySession}
                            weeklySessionUpdater={updateWeeklySession}
                            weeklySessionDeleter={deleteWeeklySession}
                            currentCourse={selectedCourse}
                            setCurrentCourse={setSelectedCourse}
                            tableData={scheduleInfo}
                        />
                        <CancelSession
                            sessionList={samSchedule[3]}
                            cancelFunction={cancelSession}
                        />
                        {/* <NonWeeklySession
                            addFunction={addSession}
                            deleteFunction={deleteSession}
                            updateFunction={updateSession}
                            sessionList={samSchedule[0]}
                            selectedCourseID={selectedCourse}
                        /> */}
                    </div>
                    : ''
                }
                {(page == 'materials') ?
                    <div className="px-3">
                        <FileSharing
                            courseID={selectedCourse}
                        />
                    </div>
                    : ''
                }
            </div>
        </div>
    );
};

export default SamDashboard;