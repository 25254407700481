import React from "react";
import { useState } from "react";
import axios from "axios";
function AskSam({ message, courseID }) {
    const [askSamMessage, setAskSamMessage] = useState(message);
    //Helper function that submits the students ask sam message
    const submitAskSam = () => {
        const message = document.getElementById('askSamText').value;
        console.log("message is ", message);
        if (message.length === 0) {
            setAskSamMessage("Question cannot be empty");
            return;
        } else {
            setAskSamMessage("Processing...");
            axios.post(`https://api.sam.cs.mtu.edu/api/course/asksam/${parseInt(courseID)}`, { question: message }, { withCredentials: true })
                .then(response => {
                    setAskSamMessage("Message Sent!")
                    document.getElementById('askSamText').value = "";
                })
                .catch((error => {
                    console.log(error);
                    setAskSamMessage("Message failed to send!");
                }))
        }

    }
    return (
        <section className="pt-70 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8  offset-lg-2">
                        <div className="contact-form-area">
                            <h6 className="text-center">Have Questions? Ask a SAM</h6>
                            {askSamMessage && (
                                <p className="alert alert-info mt-3">{askSamMessage}</p>
                            )}
                            <form className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <textarea className="form-control" rows="5" placeholder={"Ask your question here..."} defaultValue="" id="askSamText"></textarea>
                                    </div>
                                    <div className="form-group m-0 text-center">
                                        <button className="myBtn" onClick={submitAskSam}>Send message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AskSam;