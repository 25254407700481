import React from "react";
import '../Styles/WarningBox.css';
function SignoutWarning({ message, dismissWarning }) {
    //Sets the warning message back to null which unloads this component
    const dismiss = () => {
        dismissWarning(null);
    }
    return (
        <div id="signoutWarningWrapper">
            <div id="signoutWarningInnerBox">
                <p id="signoutWarningMessage">{message}</p>
                <button id="dismissWarning" onClick={dismiss}>OK</button>
            </div>
        </div>
    )
}
export default SignoutWarning;