import React, { useState } from "react";
import axios from "axios";
import ConfirmationModal from "../SAMDashComponents/ConfirmationModal";

function DownloadMaterial({ courseID, entries, fetchFiles }) {
  console.log(entries);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [materialToDelete, setMaterialToDelete] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null); 
  const [previewError, setPreviewError] = useState(null); 

  const getSelections = () => {
    let returnArr = [];
    for (const [key, value] of entries) {
      returnArr.push(
        <option
          key={`materialEntry-${courseID}-${key}`}
          value={key}
        >
          {value.name}
        </option>
      );
    }
    return returnArr;
  };

  const checkSelect = (event) => {
    const selectedValue = parseInt(event.target.value);
    setSelectedMaterial(selectedValue === 0 ? null : selectedValue);
    setPreviewUrl(null); 
    setPreviewError(null); 
  };

  const getEntries = () => {
    return (
      <select
        onChange={checkSelect}
        key={`samMaterialOptions-${courseID}`}
        className="form-select mb-3"
      >
        <option value={0}>Please make a selection</option>
        {getSelections()}
      </select>
    );
  };

  const confirmDeleteMaterial = (id) => {
    setMaterialToDelete(id);
    setShowDeleteModal(true);
  };

  const deletePost = () => {
    if (materialToDelete !== null) {
      axios.delete(`https://api.sam.cs.mtu.edu/api/sam/deletematerials/${materialToDelete}`, {
        withCredentials: true
      }).then(() => {
        fetchFiles();
        setDeleteMessage("Delete Successful");
      }).catch(error => {
        setDeleteMessage("Delete Failed!");
        console.log(error);
      });
      setSelectedMaterial(null);
      setShowDeleteModal(false);
      setMaterialToDelete(null);
    }
  };

  const getMaterialInfo = () => {
    if (selectedMaterial === null) return null;
    const materialData = entries.get(selectedMaterial);
    
    if (!materialData) return null; // Add a check to ensure materialData is defined

    return (
      <div className="mt-3">
        <p className="text-secondary m-0">
          Uploaded on: {materialData.postTime}
        </p>
        <p className="text-secondary m-0">
          {materialData.comments ? `Comments: ${materialData.comments}` : "No comments provided"}
        </p>
        <div className="mt-3 d-flex gap-1">
          <button
            className="myBtn"
            onClick={downloadMats}
          >
            Download
          </button>
          <button
            className="mySecBtn"
            onClick={previewMaterial}
          >
            Preview
          </button>
          <button
            className="myDanBtn"
            onClick={() => confirmDeleteMaterial(materialData?.id)}
          >
            Delete
          </button>
        </div>
      </div>
    );
  };

  const previewMaterial = () => {
    const materialData = entries.get(selectedMaterial);
    if (!materialData) {
      setPreviewError("No material selected for preview.");
      return;
    }

    const fileUrl = `https://api.sam.cs.mtu.edu/${materialData.fileLocation}`;
    const fileType = materialData.name.split('.').pop().toLowerCase();

    if (fileType === 'pdf') {
      // Display PDFs in an iframe
      setPreviewUrl(fileUrl);
      setPreviewError(null);
    } else if (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png') {
      // Display images in an img tag
      setPreviewUrl(fileUrl);
      setPreviewError(null);
    } else if (fileType === 'txt') {
      // Fetch text files as plain text for preview
      axios.get(fileUrl, { responseType: 'text' })
        .then(response => {
          setPreviewUrl(null); // Clear iframe/img preview URL if any
          setPreviewError(null);
          const previewText = response.data;
          const blob = new Blob([previewText], { type: 'text/plain' });
          setPreviewUrl(URL.createObjectURL(blob));
        })
        .catch(() => setPreviewError("Failed to load preview for this file type."));
    } else {
      setPreviewError("Preview is not available for this file type.");
    }
  };

  const downloadMats = () => {
    const materialData = entries.get(selectedMaterial);
    if (!materialData) return; // Check if materialData is defined

    const clientFilename = materialData.name;
    axios
      .get(
        `https://api.sam.cs.mtu.edu/${materialData.fileLocation}`,
        {
          responseType: "blob",
        },
      )
      .then((response) => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", clientFilename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  return (
    <div className="container my-5">
      <h5 className="text-left text-dark mb-3">Course Materials</h5>
      {deleteMessage && (
        <p className="alert alert-warning mt-3">{deleteMessage}</p>
      )}
      {entries ? getEntries() : <h5 className="text-muted">Loading...</h5>}
      {entries && selectedMaterial !== null ? getMaterialInfo() : null}

      {previewError && (
        <p className="text-danger mt-3">{previewError}</p>
      )}

      {/* Display Preview */}
      {previewUrl && !previewError && (
        <div className="mt-4">
          <h5>Preview:</h5>
          {entries.get(selectedMaterial)?.name.endsWith(".txt") ? (
            <textarea
              className="form-control"
              readOnly
              rows="10"
              value={previewUrl ? URL.createObjectURL(previewUrl) : ""}
            />
          ) : entries.get(selectedMaterial)?.name.endsWith(".pdf") ? (
            <iframe
              src={previewUrl}
              title="PDF Preview"
              style={{ width: '100%', height: '500px', border: '1px solid #ccc' }}
            />
          ) : (
            <img
              src={previewUrl}
              alt="Document Preview"
              style={{ width: '100%', maxHeight: '500px', objectFit: 'contain', border: '1px solid #ccc' }}
            />
          )}
        </div>
      )}

      <ConfirmationModal
        show={showDeleteModal}
        title="Confirm Deletion"
        message="Are you sure you want to delete this material?"
        onConfirm={deletePost}
        onCancel={() => setShowDeleteModal(false)}
      />
    </div>
  );
}

export default DownloadMaterial;
