/**
 * Joshua Alele-Beals
 * joshbeals22@gmail.com
 * github.com/joshBeals
 */
import React, { createContext, useContext, useEffect, useState } from 'react';

const AppStateContext = createContext();

export const AppStateProvider = ({ children }) => {

  // const [convictions, setConvictions] = useState(() => {
  //   const savedConviction = localStorage.getItem('ConvictionContext');
  //   return savedConviction ? JSON.parse(savedConviction) : [];
  // });

  const [selectedCourse, setSelectedCourse] = useState(0);

  useEffect(() => {
    // localStorage.setItem('ConvictionContext', JSON.stringify(convictions));
  }, [selectedCourse]);

  // Value to be passed to consuming components
  const value = {
    selectedCourse,
    setSelectedCourse,
  };

  return <AppStateContext.Provider value={value}>{children}</AppStateContext.Provider>;
};

export const useAppState = () => useContext(AppStateContext);
