import React from "react";
import "../Styles/root.css";
function Footer() {
  const openEmail = () => {
    window.location.href = "mailto:sam-l@mtu.edu";
  };
  return (
    <div className="footerWrapper" style={{ borderTop: "1px solid gray" }}>
      <ul className="footerList">
        <li className="footerListItem" onClick={openEmail}>
          Contact Us at sam-l@mtu.edu
        </li>
      </ul>
    </div>
  );
}
export default Footer;
