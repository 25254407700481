import React from "react";
import { useState, useEffect, useRef } from "react";
import ConfirmationModal from "./ConfirmationModal";
import { useAppState } from "../../store/AppStateContext";
function CancelSession({ sessionList, cancelFunction }) {

  const [sessionMessage, setSessionMessage] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const {selectedCourse, setSelectedCourse} = useAppState();

  const clearMessage = useRef(null);
  useEffect(() => {
    if (sessionMessage !== null) {
      clearTimeout(clearMessage.current);
      clearMessage.current = setTimeout(() => {
        setSessionMessage(null);
      }, 5000);
    }
  }, [sessionMessage]);
  const getSessions = () => {
    let returnSeshs = sessionList?.filter((session) => session.courseID == selectedCourse)?.map((session) => {
      if (session.weeklySessionID != null)
        return (
          <option
            key={`cancelSessionOption-${session.sessionID}`}
            className="cancelSessionOption"
            value={session.sessionID}
          >
            {session.Date}
          </option>
        );
    });
    return returnSeshs;
  };
  const checkSelection = () => {
    const options = document.getElementsByClassName("cancelSessionOption");
    for (let i = 0; i < options.length; i++) {
      if (options[i]?.selected) {
        if (parseInt(options[i].value) == 0) {
          setSessionMessage("Please select a valid session");
        } else {
          setSessionMessage("Processing...");
          cancelFunction(options[i].value)
            .then(() => {
              setSessionMessage("Session Deleted!");
              options[i].remove();
            })
            .catch(() => setSessionMessage("Deletion failed!"));
        }
      }
    }
  };
  const checkCancel = () => {
    const options = document.getElementsByClassName("cancelSessionOption");
    for (let i = 0; i < options.length; i++) {
      if (options[i]?.selected) {
        if (parseInt(options[i].value) == 0) {
          setSessionMessage("Please select a valid session");
        } else {
          setShowDeleteModal(true);
        }
      }
    }
  }
  return (
    <div className="col-12 col-lg-6 col-md-8">
      <h5 className="text-left text-dark mb-3">Cancel Weekly Session</h5>
      {sessionList != null && sessionList != undefined ? (
        <select className="form-select" defaultValue={0}>
          <option className="cancelSessionOption" value={0}>
            Please select a session
          </option>
          {getSessions()}
        </select>
      ) : (
        <p className="loadingMessage">Loading...</p>
      )}
      <button className="mt-2 mySmBtn w-100" onClick={checkCancel}>
        Cancel Session
      </button>
      {sessionMessage ? <p className="alert alert-info mt-3 text-center">{sessionMessage}</p> : ""}
      <ConfirmationModal
        show={showDeleteModal}
        title="Confirm Cancellation"
        message="Are you sure you want to cancel this session?"
        onConfirm={() => {
          checkSelection();
          setShowDeleteModal(false);
        }}
        onCancel={() => setShowDeleteModal(false)}
      />
    </div>
  );
}

export default CancelSession;
