import React from "react";
import "../Styles/Nav.css";
function SideNav({
  navItemsMap,
  scrollFunction,
  currentCourse,
  updateCourse,
  courseList,
}) {
  const getEntries = () => {
    let listSet = [];
    if (currentCourse !== null) {
      if (parseInt(currentCourse) > 3000) {
        listSet.push(
          <li
            key={`sideNavItem-currentCourse`}
            className="sideNavItem noHoverEffect"
          >
            Current Course: CS{currentCourse}
          </li>,
        );
      } else {
        let currentCourseID = 0;
        courseList.forEach((course) => {
          if (course.scheduleID === currentCourse) {
            listSet.push(
              <li
                key={`sideNavItem-currentCourse`}
                className="sideNavItem noHoverEffect"
              >
                Current Course: CS{course.courseID} {course.semester}{" "}
                {course.year}
              </li>,
            );
          }
        });
      }
    }
    for (let i = 0; i < navItemsMap.length; i++) {
      listSet.push(
        <li
          onClick={scrollTo}
          key={`sideNavItem-${i}`}
          className="sideNavItem"
          value={i}
        >
          {navItemsMap[i]}
        </li>,
      );
    }
    if (courseList !== null) {
      if (!(courseList instanceof Set)) {
        if (courseList.length > 1) {
          listSet.push(
            <li
              key={`sideNavCourseDropdown`}
              className="sideNavItem sideNavDropdown"
            >
              <span className="courseSelectorText">Change Course</span>
              <select
                defaultValue={currentCourse}
                onChange={changeCourse}
                className="sideNavCourseSelect"
              >
                {courseList.map((course) => (
                  <option
                    key={`sideNavEntryFor-${course.scheduleID}`}
                    value={course.scheduleID}
                    className="sideNavCourseOption"
                  >
                    CS{course.courseID} {course.semester} {course.year}
                  </option>
                ))}
              </select>
            </li>,
          );
        }
      } else if (courseList instanceof Set) {
        if (courseList.size > 1) {
          listSet.push(
            <li
              key={`sideNavCourseDropdown`}
              className="sideNavItem sideNavDropdown"
            >
              <span className="courseSelectorText">Change Course</span>
              <select
                defaultValue={currentCourse}
                onChange={changeCourse}
                className="sideNavCourseSelect"
              >
                {Array.from(courseList).map((course) => (
                  <option
                    key={`sideNavEntryFor-${course}`}
                    value={course}
                    className="sideNavCourseOption"
                  >
                    CS{course}
                  </option>
                ))}
              </select>
            </li>,
          );
        }
      }
    }

    return listSet;
  };
  const changeCourse = () => {
    const options = document.getElementsByClassName("sideNavCourseOption");
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        updateCourse(options[i].value);
        return;
      }
    }
  };
  const scrollTo = (event) => {
    let index = parseInt(event.target.value);
    scrollFunction(index);
  };
  return (
    <div className="sideNavWrapper">
      <ul className="sideNavList">{getEntries()}</ul>
    </div>
  );
}
export default SideNav;
