import React, { useState } from 'react';

const TabsArea = () => {
    const [activeTab, setActiveTab] = useState('students'); // State to manage active tab

    const handleTabClick = (tab) => {
        setActiveTab(tab); // Update the active tab state
    };

    return (
        <div className="tabs-area">
            <ul className="nav mb-60" role="tablist">
                <li className="nav-item">
                    <button
                        className={`nav-link ${activeTab === 'students' ? 'active' : ''}`}
                        onClick={() => handleTabClick('students')}
                        role="tab"
                        aria-controls="students"
                        aria-selected={activeTab === 'students'}
                    >
                        For Students Seeking Support
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        className={`nav-link ${activeTab === 'sams' ? 'active' : ''}`}
                        onClick={() => handleTabClick('sams')}
                        role="tab"
                        aria-controls="sams"
                        aria-selected={activeTab === 'sams'}
                    >
                        For Prospective SAMs
                    </button>
                </li>
            </ul>
            <div className="tab-content tabs-caption">
                <div
                    className={`tab-pane fade ${activeTab === 'students' ? 'show active' : ''}`}
                    id="students"
                    role="tabpanel"
                    aria-labelledby="students-tab"
                >
                    <div className="row">
                        <div className="col-lg-12 align-self-lg-center">
                            <div className="text">
                                <p style={{ color: 'black' }}>Participating in these sessions offers you valuable assistance to
                                enhance your learning experience and achieve your academic goals.</p>
                                <h6 className="small-title" style={{ color: 'black' }}>How to Benefit from SAM</h6>
                                <ul className="list-group list-group-icon">
                                    <li className="list-group-item"><span className='tabspan'>Join Study Sessions: </span>Attend
                                    peer-led study sessions where SAMs assist with course material,
                                    clarify challenging concepts, and guide group discussions.</li>
                                    <li className="list-group-item"><span className='tabspan'>Engage in Mentoring:</span> Participate in mentoring activities to improve your study
                                    habits and deepen your understanding of the coursework.</li>
                                    <li className="list-group-item"><span className='tabspan'>Receive Personalized Help:</span> Utilize one-on-one mentoring from SAMs for tailored support
                                    with specific challenges or questions.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className={`tab-pane fade ${activeTab === 'sams' ? 'show active' : ''}`}
                    id="sams"
                    role="tabpanel"
                    aria-labelledby="sams-tab"
                >
                    <div className="row">
                        <div className="col-lg-12 align-self-lg-center">
                            <div className="text">
                                <p style={{ color: 'black' }}>We are committed to supporting your academic journey and helping you
                                achieve your goals. For more information or if you have any
                                questions, please reach out to us. We look forward to your
                                participation and contributions!</p>
                                <h6 className="small-title" style={{ color: 'black' }}>Why Join the SAM Program?</h6>
                                <ul className="list-group list-group-icon">
                                    <li className="list-group-item"><span className='tabspan'>Support Inclusivity:</span> Play a
                                    key role in creating a welcoming and supportive atmosphere within
                                    the CS Department, positively impacting all students, including
                                    those traditionally underrepresented in computing.</li>
                                    <li className="list-group-item"><span className='tabspan'>Develop Essential Skills:</span> Gain valuable experience in leadership, mentoring, and
                                    teaching, while enhancing your communication, teamwork, and
                                    mentoring abilities.</li>
                                    <li className="list-group-item"><span className='tabspan'>Enhance Your Resume:</span> Build a
                                    strong resume with meaningful mentoring experience and
                                    opportunities to collaborate closely with faculty role models.</li>
                                </ul>
                            </div>
                            <div className="text mt-3">
                                <h6 className="small-title" style={{ color: 'black' }}>What You'll Do</h6>
                                <ul className="list-group list-group-icon">
                                    <li className="list-group-item"><span className='tabspan'>Lead Study Sessions:</span> Facilitate small group study sessions for courses like CS2311
                                    (Discrete Structures), CS2321 (Data Structures), CS3411 (Systems
                                    Programming), and CS3331 (Concurrent Programming).</li>
                                    <li className="list-group-item"><span className='tabspan'>Engage in Training:</span> Participate in weekly mentor training and team discussions
                                    to develop and refine your mentoring skills.</li>
                                    <li className="list-group-item"><span className='tabspan'>Receive Competitive Pay:</span> Enjoy a time commitment of up to 6 hours per week with a
                                    competitive pay rate of $17.50 per hour for new SAMs and $18.00
                                    per hour for returning SAMs.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TabsArea;
